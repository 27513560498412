import { Component, ContentChildren, Directive, forwardRef, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Directive({
  selector: 'checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultipleButtonDirective),
      multi: true,
    },
  ],
})
export class CheckboxMultipleButtonDirective extends FormElement implements ControlValueAccessor {
  @Input() label;
}
@Component({
  selector: 'fullstack-input-check-box',
  templateUrl: './input-check-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCheckBoxComponent),
      multi: true,
    },
  ],
})
export class InputCheckBoxComponent extends FormElement implements ControlValueAccessor {
  @ContentChildren(CheckboxMultipleButtonDirective) checkboxButtons: QueryList<any>;
  @Input() options: { label: string; labelTrue: string; labelFalse: string };
  @Input() hideLabel: boolean = false;
  currentLabelValue: string;

  ngOnInit(): void {
    this.options.labelTrue = this.options.labelTrue || 'Si';
    this.options.labelFalse = this.options.labelFalse || 'No';
  }

  writeValue(value: boolean) {
    this.value = value;
    this.refreshUI(value);
  }

  refreshUI(event) {
    this.currentLabelValue = event ? this.options.labelTrue : this.options.labelFalse;
  }
}
