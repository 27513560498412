import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonRoles } from '@renovars/common';
import { AuthServices } from '../services';
@Directive({ selector: '[permissions]' })
export class PermissionDirective {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthServices
  ) {}

  OnInit() {}

  @Input()
  set permissions(permissions: [string]) {
    if (permissions) {
      this.authService.user().subscribe((user) => {
        let roles = user.roles;
        if (roles.indexOf(CommonRoles.IS_ADMIN) !== -1 || roles.find((r) => permissions.indexOf(r) !== -1)) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          return;
        }
        this.viewContainer.clear();
      });
    }
  }
}
