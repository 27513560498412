import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'renovars-sort-gallery-modal',
  templateUrl: './sort-gallery-modal.component.html',
  styleUrls: ['./sort-gallery-modal.component.scss']
})
export class SortGalleryModalComponent implements OnInit {
  storageUrl: string;
  images = [];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
  }

  constructor(
    public dialogRef: MatDialogRef<SortGalleryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {
    this.images = this.data.gallery.map(f => {

      return { ...f.file, tag: f.tag };
    });
    this.storageUrl = this.data.storageUrl;
  }


  reorder() {
    const ordered = this.images.map((image, index) => {
      return { _id: image._id, position: index }
    })
    this.dialogRef.close(ordered)
  }
}
