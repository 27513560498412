import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { get } from 'lodash';
import { filter, debounceTime } from 'rxjs';
import { FormElement } from '../core/utils/form-element';
import { BoardDirective } from './kanban.directives';

@Component({
  selector: 'fullstack-kanban',
  template: `<div>
    <cdk-virtual-scroll-viewport itemSize="5">
      <div class="scrolling-wrapper-flexbox pt-0" cdkScrollable>
        <div *cdkVirtualFor="let b of boards; let i = index" class="col-cs-250 m-2 board">
          <header class="page-header">
            <h2 class="tx-title-2">{{ b.title }}</h2>
            <h6>{{ b.list?.length }}</h6>
          </header>
          <div *ngIf="list">
            <div class="example-container">
              <div
                class="card-list"
                cdkDropList
                id="{{ b.value }}"
                [cdkDropListData]="b"
                [cdkDropListConnectedTo]="cdkDropListConnectedToList"
                [cdkDropListDisabled]="b.disabled"
                [cdkDropListEnterPredicate]="enterPredicate"
                (cdkDropListDropped)="onDrop($event)"
              >
                <ng-container *ngFor="let elem of b.list; let i = index">
                  <div class="card card--kanban" cdkDrag>
                    <ng-container
                      *ngTemplateOutlet="cardTemplate; context: { $implicit: elem, board: b }"
                    ></ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>`,
  styleUrls: [`./kanban.component.scss`],
})
export class KanbanComponent extends FormElement implements OnInit, AfterContentInit, OnChanges {
  @ContentChildren(BoardDirective) boards: BoardDirective[];
  @ContentChild('cardTemplate') public cardTemplate;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Output() boardDrop = new EventEmitter();
  @Output() endReached = new EventEmitter();
  @Input() list;
  @Input() selectorField;

  ID_DROP_ELEM = 'drop';
  cdkDropListConnectedToList;
  count = 0;
  constructor(private disp: ScrollDispatcher) {
    super();
  }
  ngOnInit(): void {
    this.disp
      .scrolled()
      .pipe(
        filter(() => this.virtualScroll.measureScrollOffset('bottom') < 300),
        debounceTime(500)
      )
      .subscribe(() => {
        this.endReached.emit();
        this.loadData();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list) {
      this.loadData();
    }
  }

  ngAfterContentInit(): void {
    this.cdkDropListConnectedToList = this.boards.map((b) => b.value.toString());
    this.loadData();
  }

  loadData() {
    if (this.list && this.selectorField && this.boards) {
      this.boards.forEach((b) => {
        if (b.value) {
          b.list = this.list.filter((l) => get(l, this.selectorField) == b.value);
        }
      });
    }
  }

  enterPredicate(drag: CdkDrag<BoardDirective>, drop: CdkDropList) {
    return !drop.data.disabled;
  }

  onDrop(event: CdkDragDrop<BoardDirective>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data.list, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.list,
        event.container.data.list,
        event.previousIndex,
        event.currentIndex
      );

      this.boardDrop.emit({
        item: event.container.data.list[event.currentIndex],
        previousValue: event.previousContainer.id,
        currentValue: event.container.id,
      });
    }
  }
}
