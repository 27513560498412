import { NgModule } from '@angular/core';
import { GMapModule } from 'primeng/gmap';
import { CommonModule } from '@angular/common';
import { EntitiesMapComponent } from './entities-map.component';

const COMPONENTS = [EntitiesMapComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, GMapModule],
  exports: COMPONENTS,
})
export class EntitiesMapModule {}
