import { IContact, ContactTypeEnum, IContactIndividual, IContactCompany } from "@renovars/common";
import * as _ from "lodash";

export const validateBillingContact = (contact: IContact) => {
  let isValid = true;
  const reasons: string[] = []
  if (!contact) {
    reasons.push('Compila tutti i campi del form');
    isValid = false;
  } else {
    if (contact.type === ContactTypeEnum.INDIVIDUAL) {
      // validate individual
      const individualContact: IContactIndividual = contact;
      if (_.isEmpty(individualContact.firstName)) {
        isValid = false;
        reasons.push('• Il campo Nome non può essere vuoto');
      }
      if (_.isEmpty(individualContact.lastName)) {
        isValid = false;
        reasons.push('• Il campo Cognome non può essere vuoto');
      }
      if (_.isEmpty(individualContact.billingAddress)) {
        isValid = false;
        reasons.push('• Il campo Indirizzo di fatturazione non può essere vuoto');
      }
      if (_.isEmpty(individualContact.fiscalCode)) {
        isValid = false;
        reasons.push('• Il campo Codice fiscale non può essere vuoto');
      }
      if (!individualContact?.emails || (individualContact?.emails?.length > 0 && !individualContact?.emails[0].email)) {
        isValid = false;
        reasons.push('• Il campo Email non può essere vuoto');
      }
      if (!individualContact?.phones || (individualContact?.phones?.length > 0 && !individualContact?.phones[0].phoneNumber)) {
        isValid = false;
        reasons.push('• Il campo Telefono non può essere vuoto');
      }

    } else {
      // validate company
      const companyContact: IContactCompany = contact;
      if (_.isEmpty(companyContact.businessName)) {
        isValid = false;
        reasons.push('• Il campo Ragione Sociale non può essere vuoto');
      }
      if (_.isEmpty(companyContact.legalAddress)) {
        isValid = false;
        reasons.push('• Il campo Indirizzo di fatturazione non può essere vuoto');
      }
      if (_.isEmpty(companyContact.fiscalCode)) {
        isValid = false;
        reasons.push('• Il campo Codice fiscale non può essere vuoto');
      }
      /*       if (_.isEmpty(companyContact.taxCode)) {
              isValid = false;
              reasons.push('• Il campo Codice ritenuta non può essere vuoto');
            } */
      if (_.isEmpty(companyContact.vatCode)) {
        isValid = false;
        reasons.push('• Il campo Partita IVA non può essere vuoto');
      }
      if (_.isEmpty(companyContact.sdiCode)) {
        isValid = false;
        reasons.push('• Il campo Codice destinatario non può essere vuoto');
      }
      if (!companyContact?.emails || (companyContact?.emails?.length > 0 && !companyContact?.emails[0].email)) {
        isValid = false;
        reasons.push('• Il campo Email non può essere vuoto');
      }
      if (!companyContact?.phones || (companyContact?.phones?.length > 0 && !companyContact?.phones[0].phoneNumber)) {
        isValid = false;
        reasons.push('• Il campo Telefono non può essere vuoto');
      }
    }
  }


  return { isValid, reasons };
}
