<fullstack-header></fullstack-header>

<ng-template header-body>
  <h1 class="tx-title-1">Network</h1>
  <!-- <h2 class="tx-subtitle mb-0"></h2> -->
</ng-template>

<!-- <ng-template header-menu>
  <fullstack-tabs>
    <tab [label]="'network'" [url]="'./summary'"></tab>
  </fullstack-tabs>
</ng-template> -->

<router-outlet></router-outlet>
