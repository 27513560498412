import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { ContactTypeEnum } from '@renovars/common';
import { setFieldsProperties } from '@renovars/core-ng';

@Component({
  selector: 'fullstack-contact-individual-form',
  templateUrl: './contact-individual-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactIndividualFormComponent),
      multi: true,
    },
  ],
})
export class ContactIndividualFormComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    setFieldsProperties(this._formInfoState, this.form);
  }
  get formInfoState() {
    return this._formInfoState;
  }
  @Input() sources: string[];
  @Input() showSources = true;
  @Input() showBillingAddress = true;
  @Input() showPhoneType = true;
  @Input() showMailType = true;

  _formInfoState: ValidationErrors = [];

  form = this.fb.group({
    /* type: new FormControl('', [Validators.required]), */
    id: new UntypedFormControl(null, []),
    type: new UntypedFormControl(ContactTypeEnum.INDIVIDUAL, []),
    firstName: new UntypedFormControl(null, []),
    lastName: new UntypedFormControl(null, []),
    birthDate: new UntypedFormControl(null, []),
    birthPlace: new UntypedFormControl(null, []),
    gender: new UntypedFormControl(null, []),
    fiscalCode: new UntypedFormControl(null, []),
    address: new UntypedFormControl(null, []),
    billingAddress: new UntypedFormControl(null, []),
    iban: new UntypedFormControl(null, []),
    emails: new UntypedFormControl([], []),
    phones: new UntypedFormControl([], []),
    state: new UntypedFormControl(null, []),
    source: new UntypedFormControl(null, []),
  });
  isDisabled = false;
  propagateChange: any = () => {};

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange(val);
    });
  }

  phonesGroupControl() {
    return this.fb.group({
      phoneNumber: new UntypedFormControl(null, []),
      type: new UntypedFormControl(null, []),
    });
  }

  emailsGroupControl() {
    return this.fb.group({
      email: new UntypedFormControl(null, []),
      type: new UntypedFormControl(null, []),
      note: new UntypedFormControl(null, []),
    });
  }

  resetFormValue() {
    this.form.reset();
    this.form.get('type').setValue(ContactTypeEnum.INDIVIDUAL);
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
