<div class="row">
  <div *ngFor="let img of imagesQueryList; let i = index" class="col-lg-5 col-xl-4">
    <button class="btn btn-secondary rounded delete-button" (click)="onDelete.emit(i)">
      <i class="far fa-trash-alt"></i>
    </button>
    <figure class="gallery__fig-thumb is-box" (click)="viewImage(img.src)">
      <img class="gallery__thumb" [src]="img.src" alt="images" />
    </figure>
  </div>
  <div class="col-lg-5 col-xl-4">
    <label class="gallery__file is-box"><i class="fas fa-camera"></i>
      <input type="file" (change)="onAddFn($event)" />
    </label>
  </div>
</div>
