import { AssignmentCommissionType, IFiSaleProject, IProposal } from '../interfaces';

export function calculateProposalValues(proposal: IProposal) {
  let commissionType = proposal.commissionType;

  let calculatedCommission = +(
    commissionType == 2 ? proposal.price * (proposal.commissionAmount / 100) : proposal.commissionAmount
  ).toFixed(2);

  return calculatedCommission;
}

export function initCaluculatedCommissions(project: IFiSaleProject, proposal: IProposal) {
  let calculatedSaleCommission = 0;
  let calculatedPurchaseCommission = 0;
  if (proposal) {
    if (project.data?.assignment?.commissionType === AssignmentCommissionType.PERCENTUALE) {
      calculatedSaleCommission =
        (proposal.price || 0) * ((project.data.assignment?.commissionAmount || 0) / 100);
    } else if (project.data?.assignment?.commissionType === AssignmentCommissionType.FISSO) {
      calculatedSaleCommission = project.data.assignment?.calculatedCommission || 0;
    }

    calculatedPurchaseCommission = proposal.calculatedCommission || 0;

    return { calculatedSaleCommission, calculatedPurchaseCommission };
  }
}
