<div class="row">
  <div [ngClass]="{ 'col-6': withRadius || withLocality, 'col-12': !withRadius && !withLocality }">
    <label class="card card--form card--dropdown" [ngClass]="{ 'is-active': isActive }">
      <span class="card-body card-dropdown-toggle">
        <span class="card-dropdown__label">
          <span class="label-control">{{ options?.label }}</span>
          <small class="p-error block" *ngIf="error"> {{ error }}</small>
          <input
            class="form-control"
            type="text"
            [ngModel]="value?.formatted_address"
            [disabled]="isDisabled"
            (ngModelChange)="onAddressChange($event)"
            placeholder="{{ options?.placeholder }}"
          />
        </span>
        <span class="card-dropdown__arrow" (click)="isActive = !isActive">
          <img src="assets/img/icn-angle-down.svg" alt="icon"
        /></span>
      </span>
      <span class="card-dropdown">
        <ul class="list-group is-scroll">
          <li *ngFor="let l of list; let i = index" class="list-group-item" (click)="onSelect(l)">
            <span>{{ l.formatted_address }}</span>
          </li>
        </ul>
        <span class="card-dropdown__footer">
          Non trovi l'indirizzo &nbsp;
          <a class="card-dropdown__link" data-toggle="modal" data-target="#modal-location">
            Inseriscilo manualmente
          </a>
        </span>
      </span>
    </label>
    <div *ngIf="value">
      <i class="cl-danger m-2" *ngIf="!value?.coords || !value.coords[0] || !value.coords[0]"
        >Indirizzo non geolocalizzato. Scrivi un'indirizzo e selezionalo dal menù a comparsa</i
      >
    </div>
  </div>
  <div class="col-6" *ngIf="withLocality">
    <label class="card card--form">
      <span class="card-body">
        <span class="label-control">Città</span>
        <input class="form-control" type="string" [(ngModel)]="locality" [disabled]="true" placeholder="" />
      </span>
    </label>
  </div>
  <div class="col-6" *ngIf="withRadius">
    <label class="card card--form">
      <span class="card-body">
        <span class="label-control">Raggio</span>
        <input
          class="form-control"
          type="number"
          [(ngModel)]="radius"
          [disabled]="isDisabled"
          (ngModelChange)="onRadiusChange($event)"
          placeholder=""
        />
      </span>
    </label>
  </div>
</div>
