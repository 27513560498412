import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IJwtPayload, IUser } from '@renovars/common';
import { map, Observable, of, switchMap } from 'rxjs';
import { AppConfigService } from '../features';
import { CacheEnum, cacheResponse } from '../utils/cache.utils';
import { UsersService } from './users.service';

@Injectable()
export class AuthServices {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private router: Router,
    private usersService: UsersService
  ) {}

  authorize(applicationId, redirectUrl): Observable<any> {
    const url = encodeURIComponent(redirectUrl);
    return this.http
      .get<any>(
        `${this.appConfigService.getApiProxiedURL()}/fusionauth/authorize?applicationId=${applicationId}&redirectUrl=${url}`
      )
      .pipe(
        switchMap((res) => {
          window.location.href = res.url;
          return of(false);
        })
      );
  }

  logout(applicationId) {
    this.http
      .get<any>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/logout/${applicationId}`)
      .subscribe((res) => {
        window.location.href = res.url;
        this.router.navigate(['/login']);
      });
  }

  refreshToken(): Observable<any> {
    return this.http.get<any>(
      `${this.appConfigService.getApiProxiedURL()}/fusionauth/refreshToken/${this.appConfigService.getApplicationId()}`
    );
  }

  user(): Observable<IJwtPayload> {
    return cacheResponse(
      CacheEnum.AUTH_USER_CACHE,
      this.http.get<IJwtPayload>(
        `${this.appConfigService.getApiProxiedURL()}/fusionauth/getAuthenticatedUser`
      )
    );
  }

  userRegistrations(id: string): Observable<IUser> {
    return cacheResponse(
      CacheEnum.USER_REGISTRATION,
      this.http.get<IUser>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/user/${id}`)
    );
  }

  userMeta() {
    return this.user().pipe(switchMap((res) => this.usersService.getUserMeta(res.sub)));
  }

  getUserRoles(): Observable<string[]> {
    return this.user().pipe(map((res) => res.roles));
  }
}
