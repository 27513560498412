import { IUtentiModuleService } from './interfaces/utenti-module-service.interface';

export const UTENTI_CONFIG_PROVIDER = 'UTENTI_CONFIG_PROVIDER';
export interface UtentiModuleOptions {
  service: IUtentiModuleService;
  userMetaEndpoint: string;
  excludeField?: {
    site?: boolean;
    bc?: boolean;
    acceptStar?: boolean;
  };
}
