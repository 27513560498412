import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import { InputBoxModule } from '../input-box/input-box.module';
import { InputSelectBoxModule } from '../input-select-box/input-select-box.module';
import { InputFileUploadComponent } from './input-file-upload.component';
import { InputFileRowDirective } from './input-file-upload.directive';
import { InputFileUploadServices } from './input-file-upload.services';

const COMPONENTS = [InputFileUploadComponent, InputFileRowDirective];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FormsModule,
    CommonModule,
    InputBoxModule,
    InputSelectBoxModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [InputFileUploadServices],
})
export class InputFileUploadModuleNew {}
