import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@renovars/fe-core-libs/guards/auth.guard';
import { FeauturesSharedModule } from '../features-shared.module';
import { HomePageComponent } from './components/pages/home-page.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent,
      },
      {
        path: 'candidati',
        loadChildren: () => import('../candidati/candidati.module').then((m) => m.CandidatiModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'users',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.UtentiModule),
      },
      // {
      //   path: 'contacts',
      //   loadChildren: () => import('@renovars/core-ng').then((m) => m.ContactModule),
      // },
      {
        path: 'profile',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.ProfileModule),
      },
      {
        path: 'network',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.NetworkModule),
      },
      {
        path: 'events',
        loadChildren: () => import('../interview/interview.module').then((m) => m.InterviewModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('../cof-calendar/cof-calendar.module').then((m) => m.CofCalendarModule),
      },
    ],
  },
];

@NgModule({
  declarations: [HomePageComponent],
  imports: [RouterModule.forChild(ROUTES), FeauturesSharedModule],
  providers: [],
})
export class HomeModule {}
