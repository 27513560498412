<label class="card card--form">
  <span class="card-body" style="min-width: 200px">
    <span class="label-control">{{ options?.label }}</span>
    <div class="d-flex flex-row justify-content-end align-items-center">
      <div>
        <mat-date-range-input [rangePicker]="picker" [min]="options?.min" [max]="options?.max">
          <input matStartDate [(ngModel)]="start" placeholder="{{ options?.placeholder }}" />
          <input matEndDate [(ngModel)]="end" (dateChange)="_rangeChange()" />
        </mat-date-range-input>
      </div>
      <button (click)="_clear()">
        <i class="fas fa-eraser"></i>
      </button>
      <div>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [calendarHeaderComponent]="DatepickerHeaderComponent"
        ></mat-date-range-picker>
      </div>
    </div>
  </span>
</label>
