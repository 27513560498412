import { Injectable } from '@angular/core';
import { CofRoles } from '@renovars/common/recruit';
import { Observable, shareReplay } from 'rxjs';
import { UsersService } from '../../../../../libs/fe/core-ng/src/lib/services/users.service';
import { IUser } from '../../../../../libs/shared/common/src';

@Injectable({
  providedIn: 'root',
})
export class CofUserCacheService {
  cachedUsers$: Observable<IUser[]>;
  constructor(private usersService: UsersService) {}

  getUsers(): Observable<IUser[]> {
    if (!this.cachedUsers$) {
      this.cachedUsers$ = this.usersService
        .excludeNetworkUsers$([CofRoles.CC_MANAGER, CofRoles.HR_OPERATOR])
        .pipe(shareReplay(1));
    }
    return this.cachedUsers$;
  }
}
