<h5 *ngIf="options?.label">{{ options?.label }}:</h5>
<div class="row pt-2 pb-3">
  <div *ngFor="let c of items; let i = index" class="col-auto">
    <div class="form-input">
      <label class="radio-control">
        <input
          class="radio-control__input"
          type="radio"
          name="choice"
          (change)="onSelect(i)"
          [checked]="selectedIndex == i"
        />
        <span class="radio-control__dot"> </span>
      </label>
      <label class="form-check-label">{{ c.label }}</label>
    </div>
  </div>
</div>
