export interface CofDashboard {
  CV_TOTALI: number;
  CV_DA_CONTATTARE: number;
  // CV_CONTATTATI: number;
  CV_IN_DA_RICHIAMARE: number;
  CV_IN_KO: number;
  CV_KO: number;
  // CV_KO_NO_INTERESSATO: number;
  // CV_KO_DISTANZA: number;
  // CV_KO_SMARTWORKING: number;
  // CV_KO_NON_IN_LINEA: number;
  CV_COLLOQUIO_FISSATO: number;
  CV_COLLOQUIO_EFFETTUATO: number;
  CV_AFFIANCAMENTO: number;
  CV_IN_FORMAZIONE: number;
  CV_OPERATIVI: number;
  CV_RAP_ASSUNTI: string;
  CV_RAP_FORMAZIONE: string;
  CV_RAP_EFFETTUATI: string;
  CV_RAP_COLLOQUIO: string;
}
export const EmptyCofDashboard = {
  CV_TOTALI: 0,
  CV_DA_CONTATTARE: 0,
  // CV_CONTATTATI: 0,
  CV_IN_DA_RICHIAMARE: 0,
  CV_IN_KO: 0,
  CV_KO: 0,
  // CV_KO_NO_INTERESSATO: 0,
  // CV_KO_DISTANZA: 0,
  // CV_KO_SMARTWORKING: 0,
  // CV_KO_NON_IN_LINEA: 0,
  CV_COLLOQUIO_FISSATO: 0,
  CV_COLLOQUIO_EFFETTUATO: 0,
  CV_AFFIANCAMENTO: 0,
  CV_IN_FORMAZIONE: 0,
  CV_OPERATIVI: 0,
  CV_RAP_ASSUNTI: '0%',
  CV_RAP_FORMAZIONE: '0%',
  CV_RAP_EFFETTUATI: '0%',
  CV_RAP_COLLOQUIO: '0%',
};
export const CofDashboardLabels = {
  CV_TOTALI: 'Cv Totali',
  CV_DA_CONTATTARE: 'Cv da contattare',
  CV_IN_DA_RICHIAMARE: 'Cv in da richiamare',
  CV_IN_KO: 'CV in Ko',
  CV_COLLOQUIO_FISSATO: 'Colloquio fissato',
  CV_COLLOQUIO_EFFETTUATO: 'Colloquio effettuato',
  CV_AFFIANCAMENTO: 'Affiancamento',
  CV_IN_FORMAZIONE: 'Formazione',
  CV_OPERATIVI: 'Assunto',
};
export const CofDashboardLabelsWhitPerc = {
  CV_TOTALI: 'Cv Totali',
  CV_DA_CONTATTARE: 'Cv da contattare',
  CV_KO: 'KO screening',
  CV_COLLOQUIO_FISSATO: 'Colloquio fissato',
  CV_COLLOQUIO_EFFETTUATO: 'Colloquio effettuato',
  CV_AFFIANCAMENTO: 'Affiancamento',
  CV_IN_FORMAZIONE: 'Formazione',
  CV_OPERATIVI: 'Assunto',
};
export const CofDashboardColors = {
  CV_TOTALI: '#ABC270', //emerald
  CV_DA_CONTATTARE: '#A6D1E6', //turquoise
  // CV_CONTATTATI: '#B4D2BA', //turquoise chiaro
  CV_IN_DA_RICHIAMARE: '#DCE2AA', //gialloverde desaturato
  CV_IN_KO: '#DEB6AB', //marroncino
  CV_KO: '#DEB6BA', //marroncino2
  CV_COLLOQUIO_FISSATO: '#98D9C2', //turquoise chiera
  CV_COLLOQUIO_EFFETTUATO: '#8EC3B0', //verde scuro
  CV_IN_FORMAZIONE: '#F5F0BB', //giallo canarino
  CV_OPERATIVI: '#5EEB5B', //verde acido
  CV_RAP_ASSUNTI: '#FDA769',
  CV_RAP_FORMAZIONE: '#FDA769',
  CV_RAP_EFFETTUATI: '#FDA769',
  CV_RAP_COLLOQUIO: '#FDA769',
};

export interface ICofDashboardRequest {
  createdAt: {
    start: Date;
    end: Date;
  };
  site?: string;
}
