<div>
  <header class="page-header border-bottom-0" (click)="addSlot()">
    <div class="d-flex justify-content-between">
      <h2 class="tx-title-4 mt-2">Slot orari</h2>
      <button type="button" class="btn btn-header" title="Aggiungi disponibilità">
        <i class="fas fa-plus cl-primary fa-1x"></i>
      </button>
    </div>
  </header>
</div>
<div *ngIf="slots.length > 0; else noSlots">
  <div class="d-flex" *ngFor="let slot of slots; let i = index">
    <div class="mb-3">
      <div class="d-flex">
        <div class="me-3">
          <label for="">Giorno</label>
          <select class="form-select" [(ngModel)]="slot.day" (change)="onSlotChange(slots)">
            <option *ngFor="let day of weekDays" [value]="day.value | number">{{ day.name }}</option>
          </select>
        </div>
        <div class="me-3">
          <label for="">Dalle ore</label>
          <select class="form-select" [(ngModel)]="slot.start" (change)="checkStartRange(i)">
            <option *ngFor="let hour of slot.startHours; let i = index" [value]="hour | number">
              {{ hour }}
            </option>
          </select>
        </div>
        <div class="me-3">
          <label for="">Alle ore</label>
          <select class="form-select" [(ngModel)]="slot.end" (change)="checkEndRange(i)">
            <option *ngFor="let hour of slot.endHours; let i = index" [value]="hour | number">
              {{ hour }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="pt-3">
      <button type="button" class="btn btn-header mt-3" (click)="removeSlot(i)" title="Rimuovi disponibilità">
        <i class="fas fa-eraser cl-primary fa-1x"></i>
      </button>
    </div>
  </div>
</div>
<ng-template #noSlots>
  <label>L'utente è sempre disponibile</label>
</ng-template>
