import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMutipleSelectBoxComponent } from './input-multiple-select-box.component';
import { InputSelectBoxComponent } from './input-select-box.component';
import { SelectBoxOptionDirective } from './input-select-box.directive';

const COMPONENTS = [InputSelectBoxComponent, SelectBoxOptionDirective, InputMutipleSelectBoxComponent];

@NgModule({
  imports: [FormsModule, CommonModule, MultiSelectModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputSelectBoxModule {}
