import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullcalendarComponent } from './fullcalendar.component';
import { FullcalendarEventDirective } from './fullcalendar.directive';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CommonModule } from '@angular/common';

const COMPONENTS = [FullcalendarComponent, FullcalendarEventDirective];

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin, timeGridPlugin]);

@NgModule({
  imports: [CommonModule, FullCalendarModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class FullcalendarModule {}
