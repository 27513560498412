import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './spinner.component';
import { SpinnerService } from './spinner.service';
import { CommonModule } from '@angular/common';

const COMPONENTS = [SpinnerComponent];

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [SpinnerService],
})
export class SpinnerModule {}
