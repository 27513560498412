import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputFileUploadComponent } from './input-file-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { InputFileUploadServices } from './input-file-upload.services';

const COMPONENTS = [InputFileUploadComponent];

@NgModule({
  imports: [FileUploadModule, FormsModule, CommonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [InputFileUploadServices],
})
export class InputFileUploadModule {}
