import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputBoxComponent } from './input-box.component';
import { InputNumberModule } from 'primeng/inputnumber';

const COMPONENTS = [InputBoxComponent];

@NgModule({
  imports: [FormsModule, CommonModule, InputNumberModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputBoxModule {}
