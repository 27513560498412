import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IContact } from '@renovars/common';
import { ContactsServices } from '@renovars/fe-core-libs/services';
import type { IFilter } from '@renovars/mongoose-nest';
import * as _ from 'lodash';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'contacts',
  template: `
    <fullstack-header></fullstack-header>

    <ng-template header-body>
      <h1 class="tx-title-1">Anagrafica</h1>
      <h2 class="tx-subtitle mb-0">Lista</h2>
    </ng-template>
    <ng-template header-body>
      <div class="text-end align-items-center">
        <button class="btn btn-header" [routerLink]="['/home/contacts/upsert']">
          <i class="fas fa-plus cl-medium fa-1x"></i>
        </button>
      </div>
    </ng-template>

    <fullstack-datatable
      [options]="{ lazy: true }"
      (lazyLoad)="onLazyLoad($event)"
      [list]="contacts$ | async"
      [cmItems]="cmItems"
      (selected)="selected = $event"
      [totalRecords]="contactsCount$ | async"
    >
      <div t-header>
        <div t-column field="firstName">
          <ng-template t-col-title>Nome</ng-template>
          <fullstack-input-box
            *t-filter
            (keyup.enter)="search()"
            [(ngModel)]="contactsFilter.where['firstName']"
          >
          </fullstack-input-box>
        </div>
        <div t-column field="lastName">
          <ng-template t-col-title>Cognome</ng-template>
          <fullstack-input-box
            *t-filter
            (keyup.enter)="search()"
            [(ngModel)]="contactsFilter.where['lastName']"
          >
          </fullstack-input-box>
        </div>
        <div t-column field="emails.email">
          <ng-template t-col-title>Email</ng-template>
          <fullstack-input-box
            *t-filter
            (keyup.enter)="search()"
            [(ngModel)]="contactsFilter.where['emails.email']"
          >
          </fullstack-input-box>
        </div>
        <div t-column field="phones.phoneNumber">
          <ng-template t-col-title>Telefono</ng-template>
          <fullstack-input-box
            *t-filter
            (keyup.enter)="search()"
            [(ngModel)]="contactsFilter.where['phones.phoneNumber']"
          >
          </fullstack-input-box>
        </div>
      </div>

      <ng-template t-body let-row>
        <td>{{ row?.firstName || row?.businessName }}</td>
        <td>{{ row?.lastName }}</td>
        <td>{{ row.emails ? row.emails[0]?.email : '' }}</td>
        <td>{{ row.phones ? row.phones[0]?.phoneNumber : '' }}</td>
      </ng-template>
    </fullstack-datatable>
  `,
})
export class ContactComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      command: () => {
        this.gotoDetail();
      },
    },
  ];
  contacts$: Observable<IContact[]>;
  selected: IContact = null;
  contactsFilter: IFilter = { where: {} };
  contactsCount$: Observable<number>;

  constructor(
    private contactsServices: ContactsServices,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.contactsFilter.skip = 0;
  }

  onLazyLoad(event: LazyLoadEvent) {
    this.contactsFilter.skip = event.first;
    this.contactsFilter.limit = event.rows;

    if (!_.isEmpty(event.sortField)) {
      this.contactsFilter.order = [];
      let sign = '';
      if (event.sortOrder.toString().length == 2) {
        sign = event.sortOrder.toString().substring(0, 1);
      }
      this.contactsFilter.order.push(sign + event.sortField);
    }

    this.search();
  }

  gotoDetail() {
    this.router.navigate(['./upsert'], {
      queryParams: { id: this.selected._id },
      relativeTo: this.activatedRoute,
    });
  }

  search() {
    this.contactsServices
      .search(this.contactsFilter)
      .pipe(
        tap((res) => (this.contacts$ = of(res.data))),
        tap((res) => (this.contactsCount$ = of(res.total)))
      )
      .subscribe();
  }
}
