import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';

const DECLARATIONS = [];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [SharedModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class FormsModule {}
