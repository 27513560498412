<label class="card card--form">
  <span class="card-body">
    <div>
      <span class="label-control">{{ options?.label }}</span>
      <small class="p-error block" *ngIf="error"> {{ error }}</small>
    </div>
    <div>
      <p-inputNumber
        *ngIf="type == 'percent'"
        [ngModel]="value"
        (ngModelChange)="modelChange($event)"
        mode="decimal"
        [minFractionDigits]="2"
        [disabled]="isDisabled || options?.disabled"
        suffix="%"
      ></p-inputNumber>
      <p-inputNumber
        *ngIf="type == 'currency'"
        mode="currency"
        currency="EUR"
        locale="it-IT"
        [ngModel]="value"
        [disabled]="isDisabled || options?.disabled"
        (ngModelChange)="modelChange($event)"
      ></p-inputNumber>
      <input
        *ngIf="type != 'currency' && type != 'percent'"
        class="form-control"
        type="{{ type }}"
        [ngModel]="value"
        (ngModelChange)="modelChange($event)"
        [disabled]="isDisabled || options?.disabled"
        placeholder="{{ options?.placeholder }}"
      />
    </div>
  </span>
</label>
