import {
  Component,
  ContentChildren,
  Directive,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';
import { AfterContentInit } from '@angular/core';

@Directive({
  selector: 'fullstack-radio-button item',
})
export class RadioButtonDirective {
  @Input() label;
  @Input() value;
}

@Component({
  selector: 'fullstack-radio-button',
  templateUrl: './radio-button.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent extends FormElement implements OnInit, AfterContentInit {
  @ContentChildren(RadioButtonDirective) items: QueryList<any>;
  @Input() options: { label: String; placeholder: String };

  selectedIndex = 0;

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.writeValue(this.value);
  }

  writeValue(val): void {
    this.value = val;
    if (this.items) {
      const i = this.items.toArray().findIndex((c) => c.value === val || c.label === val);
      this.selectedIndex = i;
    }
  }

  onSelect(index) {
    this.selectedIndex = index;
    const s = this.items.toArray()[this.selectedIndex];
    this.value = s.value || s.label;
    this._onChange(this.value);
  }
}
