<label class="card card--form">
  <span class="card-body">
    <span class="label-control">{{ options?.label }}</span>
    <small class="p-error block" *ngIf="error"> {{ error }}</small>
    <div class="row">
      <div class="col">
        <input
          class="form-control"
          type="text"
          [matDatepicker]="picker1"
          [ngModel]="value"
          (ngModelChange)="_onChange($event)"
          (change)="dateChange($event)"
          [disabled]="isDisabled"
          placeholder="{{ options?.placeholder }}"
        />
      </div>
      <div class="col-auto">
        <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </div>
    </div>
  </span>
</label>
