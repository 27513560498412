import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISite, IUser, LabelRoles } from '@renovars/common';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import dayjs from 'dayjs';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtentiModuleOptions, UTENTI_CONFIG_PROVIDER } from '../module.options';

@Component({
  selector: 'facile-utenti',
  template: `
    <fullstack-header></fullstack-header>

    <ng-template header-body>
      <h1 class="tx-title-1">Utenti</h1>
      <h2 class="tx-subtitle mb-0">Lista</h2>
    </ng-template>

    <!-- <ng-template header-body>
      <div class="text-end align-items-center">
        <button class="btn btn-header">
          <i class="fas fa-plus cl-medium fa-1x"></i>
        </button>
      </div>
    </ng-template> -->

    <fullstack-datatable [list]="users$ | async" [cmItems]="cmItems" (selected)="selected = $event" [clientExport]="true">
      <div t-header>
        <div t-column field="firstName" clientFilter="firstName">
          <ng-template t-col-title>Nome</ng-template>
        </div>
        <div t-column field="lastName" clientFilter="lastName">
          <ng-template t-col-title>Cognome</ng-template>
        </div>
        <div t-column field="email" clientFilter="email">
          <ng-template t-col-title>Email</ng-template>
        </div>
        <div t-column field="roles" clientFilter="roles">
          <ng-template t-col-title>Ruolo</ng-template>
        </div>
        <div t-column field="active">
          <ng-template t-col-title>Attivo</ng-template>
        </div>
        <div t-column field="lastLoginInstant">
          <ng-template t-col-title>Ultimo Login</ng-template>
        </div>
        <div t-column field="site.name" clientFilter="site.name">
          <ng-template t-col-title>Nome Sede</ng-template>
        </div>
        <div t-column field="site.address.province" clientFilter="site.address.province">
          <ng-template t-col-title>Provincia Sede</ng-template>
        </div>
        <div t-column field="site.area" clientFilter="site.area">
          <ng-template t-col-title>Area Sede</ng-template>
        </div>
      </div>

      <ng-template t-body let-row>
        <td>{{ row?.firstName }}</td>
        <td>{{ row?.lastName }}</td>
        <td>{{ row?.email }}</td>
        <td>{{ row?.roles }}</td>
        <td>{{ row?.active ? 'Attivo' : 'Non attivo' }}</td>
        <td>{{ row?.lastLoginInstant | date: 'short' }}</td>
        <td>{{ row?.site?.name}}</td>
        <td>{{ row?.site?.address?.province }}</td>
        <td>{{ row?.site?.area}}</td>
      </ng-template>
    </fullstack-datatable>
  `,
})
export class UtentiComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      command: () => {
        this.gotoDetail();
      },
    },
  ];

  users$: Observable<ViewUser[]>;
  selected: ViewUser = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    @Inject(UTENTI_CONFIG_PROVIDER) public utentiModuleOption: UtentiModuleOptions,

  ) {}

  ngOnInit(): void {
    console.log(this.utentiModuleOption)
    this.users$ = this.usersService.getUsers().pipe(
      map((us) => {
        return us?.map(
          (u : IUser & {site: ISite}) =>
            ({
              id: u.id,
              firstName: u.firstName,
              lastName: u.lastName,
              email: u.email,
              roles: u.registrations ? this.buildRoles(u.registrations[0].roles) : [],
              active: u.active,
              lastLoginInstant: dayjs(u.lastLoginInstant).toDate(),
              site: u.site
            } as ViewUser)
        );
      })
    );
  }

  gotoDetail() {
    this.router.navigate([`../upsert/additional-info`], {
      queryParams: { id: this.selected.id },
      relativeTo: this.activatedRoute,
    });
  }
  private buildRoles(roles: string[]) {
    return roles
      ? roles
          .map((r) => (LabelRoles[r] ? LabelRoles[r] : null))
          .filter((role) => !!role)
          .join(', ')
      : '';
  }
}
type ViewUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string;
  lastLoginInstant: Date;
  site: ISite;
};
