import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TimepickerComponent } from './timepicker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const COMPONENTS = [TimepickerComponent];

@NgModule({
  imports: [FormsModule, CommonModule, NgxMaterialTimepickerModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class TimePickerModule {}
