import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UtentiAdditionalInfo } from './components/pages/additional-info.component';
import { UtentiUpsertComponent } from './components/pages/upsert/utenti-upsert.component';
import { UtentiUpsertTabsComponent } from './components/pages/utenti-upsert-tabs.component';
import { UtentiComponent } from './components/utenti.component';

const utentiRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: UtentiComponent,
      },
      {
        path: 'upsert',
        component: UtentiUpsertTabsComponent,
        children: [
          {
            path: 'main-info',
            component: UtentiUpsertComponent,
          },
          {
            path: 'additional-info',
            component: UtentiAdditionalInfo,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(utentiRoutes)],
  exports: [RouterModule],
})
export class UtentiRoutingModule {}
