import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageDirective } from '../core';

@Component({
  selector: 'fullstack-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit, AfterContentInit {
  @ContentChildren(ImageDirective) public imagesQueryList: QueryList<any>;
  @Output() onAdd = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  ngAfterContentInit(): void {}

  ngOnInit(): void {}

  viewImage(src): void {
    const dialogRef = this.dialog.open(ImageViewDialog, {
      data: { src: src },
    });
  }

  onAddFn(event) {
    let input = event.target;
    this.onAdd.emit(input.files);
    input.value = null;
  }
}

@Component({
  selector: 'img-dialog',
  template: `
    <div mat-dialog-content>
      <img [src]="src" />
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ImageViewDialog {
  src: string;
  constructor(public dialogRef: MatDialogRef<ImageViewDialog>, @Inject(MAT_DIALOG_DATA) public data) {
    this.src = data.src;
  }
}
