import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '../../../../../ui-ng/src/lib/ui.module';
import { DomainValuesModule } from '../domain-values';
import { SourceFormComponent } from './components/forms/source-form.component';
import { SourceDetailComponent } from './components/pages/detail/source-detail.component';
import { SourceListComponent } from './components/pages/list/sources-list.component';
import { SourcesPageComponent } from './components/pages/sources-page.component';

const ROUTES: Routes = [
  {
    path: '',
    component: SourcesPageComponent,
    children: [
      { path: '', component: SourceListComponent },
      { path: 'detail', component: SourceDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [SourcesPageComponent, SourceListComponent, SourceDetailComponent, SourceFormComponent],
  exports: [],
  imports: [
    UiModule,
    CommonModule,
    FormsModule,
    DomainValuesModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
  ],
  providers: [],
})
export class SourceModule {}
