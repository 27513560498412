<h2 mat-dialog-title>Cancella evento personale</h2>
<mat-dialog-content class="mat-typography">
  <div class="row mb-2">
    <div class="col-12"><b>Inizio:</b> &nbsp;{{ event.start | date: 'short' }}</div>
  </div>

  <div class="row mb-2">
    <div class="col-12"><b>Fine:</b> &nbsp;{{ event.end | date: 'short' }}</div>
  </div>

  <div class="row mb-2" *ngIf="event.note">
    <div class="col-12"><b>Note:</b> &nbsp;{{ event.note }}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="me-2" mat-dialog-close>Annulla</button>
  <button mat-raised-button color="primary"  cdkFocusInitial (click)="delete()">
    Cancella Evento
  </button>
</mat-dialog-actions>
