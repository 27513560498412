<fullstack-spinner></fullstack-spinner>
<p-toast></p-toast>

<fullstack-applications-menu [registration]="registration"> </fullstack-applications-menu>

<div class="wrapper sidebar--collapsed" id="wrapper">
  <ng-content select="[sidebar]"></ng-content>

  <div class="content-wrapper">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
