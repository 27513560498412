import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@renovars/ui-ng';
import { SchedulingServices } from '../../services/scheduling.services';
import { SharedCoreModule } from '../../shared-core.module';
import { AppConfigModule } from '../app-config';
import { UtentiModule } from '../utenti/utenti.module';
import { ProfileAdditionalInfoComponent } from './components/pages/additional-info.component';
import { ProfileTabsComponent } from './components/pages/profile-tabs.component';
import { ProfileComponent } from './components/pages/profile.component';
import { ProfileModuleOptions, PROFILE_CONFIG_PROVIDER } from './module.options';
import { ProfileRoutingModule } from './profile-routing.module';
@NgModule({
  declarations: [ProfileComponent, ProfileTabsComponent, ProfileAdditionalInfoComponent],
  imports: [
    UiModule,
    CommonModule,
    ProfileRoutingModule,
    UtentiModule,
    ReactiveFormsModule,
    SharedCoreModule,
  ],
  providers: [SchedulingServices],
})
export class ProfileModule {
  static forRoot(config: ProfileModuleOptions): ModuleWithProviders<ProfileRoutingModule> {
    return {
      ngModule: ProfileRoutingModule,
      providers: [
        {
          provide: PROFILE_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
