import { NgModule } from '@angular/core';
import { AppConfigModule } from '../features';
import { AuthServices } from '../services';
import { AuthGuard } from './auth.guard';

const DECLARATIONS = [];

@NgModule({
  imports: [AppConfigModule.forChild()],
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  providers: [AuthGuard, AuthServices, AuthServices],
})
export class GuardsModule {}
