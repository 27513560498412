import { Routes } from '@angular/router';
import { ContactComponent } from './components/contact.component';
import { ContactUpsertComponent } from './components/pages/upsert/contact-upsert.component';

export const contactsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ContactComponent,
      },
      {
        path: 'upsert',
        component: ContactUpsertComponent,
      },
    ],
  },
];
