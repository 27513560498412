import { Component, Input } from '@angular/core';
import { IBaseCandidate } from '@renovars/common/recruit';

@Component({
  selector: 'renovars-tracking-url-details',
  templateUrl: './tracking-url-details.component.html',
  styles: [],
})
export class TrackingUrlDetailsComponent {
  @Input() candidate: IBaseCandidate;
}
