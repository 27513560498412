import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@renovars/ui-ng';
import { DomainValuesModule } from '../domain-values';
import { AppointmentListComponent } from './components/pages/list/appointment-list.component';
import { AppuntamentoModuleConfig, APPUNTAMENTO_CONFIG_PROVIDER } from './module.options';
import { EventSharedService } from './service/event-shared.service';
import { EventService } from '../../services/event.service';

const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: AppointmentListComponent,
      },
    ],
  },
];
@NgModule({
  declarations: [AppointmentListComponent],
  imports: [UiModule, CommonModule, FormsModule, RouterModule.forChild(ROUTES), DomainValuesModule],
  exports: [],
})
export class AppuntamentoModule {
  static forRoot(config: AppuntamentoModuleConfig): ModuleWithProviders<AppuntamentoModule> {
    return {
      ngModule: AppuntamentoModule,
      providers: [
        EventSharedService,
        EventService,
        {
          provide: APPUNTAMENTO_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
