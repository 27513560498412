import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputMultipleValueComponent } from './input-multiple-value.component';

const COMPONENTS = [InputMultipleValueComponent];

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputMultipleValueModule {}
