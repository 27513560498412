import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionTableComponent } from './accordion-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [CommonModule, CdkTableModule, MatTableModule],
  declarations: [AccordionTableComponent],
  exports: [AccordionTableComponent],
})
export class AccordionTableModule {}
