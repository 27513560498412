import { IContactCompany, IContactIndividual } from '.';
import { IApplication } from '../applications';
import { IModel } from '../model.interface';
import { RequestAck } from '../requests/request-ack.interface';
import { IUser } from '../users';
import { IEmail } from './email.interface';
import { IPhone } from './phone.interface';
export interface IContact extends IModel {
  _id?: string;
  type?: ContactTypeEnum | string;

  fiscalCode?: string;
  phones?: IPhone[];
  emails?: IEmail[];
  state?: string;
  source?: string;

  application?: string | IApplication;
  user?: string | IUser;
  realitycsId?: string;
  easyCallId?: string;
  duplicated?: boolean;

  customerAt?: Date; //if has completed

  sellerAt?: Date;
  sellerBillingAt?: Date;

  buyerAt?: Date;
  buyerBillingAt?: Date;

  bcId?: string; //customer bc id
}

export enum ContactTypeEnum {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}
export function isIndividual(contact: IContact): contact is IContactIndividual {
  return contact.type === ContactTypeEnum.INDIVIDUAL;
}
export function isCompany(contact: IContact): contact is IContactCompany {
  return contact.type === ContactTypeEnum.COMPANY;
}
