import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IJwtPayload } from '@renovars/common';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { AppConfigService } from '../features';
import { AuthServices } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private appConfigService: AppConfigService, private authService: AuthServices) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user().pipe(
      switchMap((user: IJwtPayload) => {
        if (user && user.aud && user.aud == this.appConfigService.getApplicationId()) {
          return of(true);
        }
      }),
      catchError((error) =>
        this.authService.authorize(
          this.appConfigService.getApplicationId(),
          this.appConfigService.getRedirectUrl()
        )
      )
    );
  }
}
