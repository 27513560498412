<ng-template header-body>
  <h2 class="tx-subtitle mb-0">Network</h2>
</ng-template>

<div class="content">
  <p-treeTable [value]="network">
    <ng-template pTemplate="header">
      <tr>
        <th>Utente</th>
        <th>Ruolo</th>
        <th>Città</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr>
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          {{ rowData.userObj?.firstName }} {{ rowData.userObj?.lastName }}
        </td>
        <td>{{ rowData.userObj | getRoles }}</td>
        <td>{{ rowData.address?.locality }}</td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
