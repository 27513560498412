import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RadioButtonComponent, RadioButtonDirective } from './radio-button.component';

const COMPONENTS = [RadioButtonComponent, RadioButtonDirective];

@NgModule({
  declarations: COMPONENTS,
  imports: [FormsModule, CommonModule],
  exports: COMPONENTS,
})
export class RadioButtonModule {}
