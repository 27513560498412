import { NgModule } from '@angular/core';
import { PermissionDirective } from './directives/permission.directive';

@NgModule({
  declarations: [PermissionDirective],
  imports: [],
  providers: [],
  exports: [PermissionDirective],
})
export class SharedCoreModule {}
