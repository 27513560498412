import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxButtonComponent, CheckboxButtonDirective } from './checkbox-button.component';

const COMPONENTS = [CheckboxButtonComponent, CheckboxButtonDirective];

@NgModule({
  declarations: COMPONENTS,
  imports: [FormsModule, CommonModule],
  exports: COMPONENTS,
})
export class CheckboxButtonModule {}
