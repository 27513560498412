import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/common';
import { FormElement } from '../core/utils/form-element';
import { FullcalendarEventDirective } from './fullcalendar.directive';
@Component({
  selector: 'fullstack-fullcalendar',
  templateUrl: './fullcalendar.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FullcalendarComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .fc-daygrid-event {
        cursor: pointer;
      }
    `,
  ],
})
export class FullcalendarComponent extends FormElement implements OnInit, AfterContentInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @Input() options: { label?: string; placeholder?: string } = {};
  @Input() list;
  @Output() viewChange = new EventEmitter();
  @Output() eventClick = new EventEmitter();
  @Output() dateClick = new EventEmitter();
  @ContentChildren(FullcalendarEventDirective) eventsChildren: QueryList<FullcalendarEventDirective>;

  calendarOptions: CalendarOptions = {
    buttonText: {
      day: 'Giorno',
      week: 'Settimana',
      month: 'Mese'
    },
    locale: 'it',
    firstDay: 1,
    customButtons: {
      prev: {
        text: 'Precedente',
        click: () => {
          this.calendarComponent.getApi().prev();
          this._viewChange();
        },
      },
      next: {
        text: 'Prossimo',
        click: () => {
          this.calendarComponent.getApi().next();
          this._viewChange();
        },
      },
      today: {
        text: 'Oggi',
        click: () => {
          this.calendarComponent.getApi().today();
          this._viewChange();
        },
      },
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
    headerToolbar: {
      start: 'today',
      center: 'title',
      end: 'prev,next,timeGridDay,timeGridWeek,dayGridMonth',
    },
    selectable: true,
    themeSystem: 'bootstrap',
    viewDidMount: () => {
      this._viewChange();
    },
    eventClick: (event) => {
      this.eventClick.emit(event);
    },
    dateClick: (arg) => {
      if (arg?.view?.type === 'timeGridWeek' || arg?.view?.type === 'timeGridDay') {
        this.dateClick.emit(arg);
      }
    },
    dayMaxEvents: 4,
    events: null,
  };

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.eventsChildren.changes.subscribe((res: QueryList<FullcalendarEventDirective>) => {
      this._dataChange(res.toArray());
    });
    this.eventsChildren.notifyOnChanges();
  }

  _dataChange(events: FullcalendarEventDirective[]) {
    this.calendarOptions.events = events.map((e) => e.event);
  }

  _viewChange() {
    const calendarApi = this.calendarComponent.getApi();
    const data = {
      currentStart: calendarApi.view.currentStart,
      currentEnd: calendarApi.view.currentEnd,
    };

    this.viewChange.emit(data);
  }
}
