import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

export const CUSTOM_DATE_FORMATS =
{
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat == "input") {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
        } else {
            return date.toDateString();
        }
    }
    parse(value: any) {
        if (!this.isDate(value)) return null;
        let splitted;
        if (this.isSlash(value)) splitted = value.split('/');
        if (this.isDot(value)) splitted = value.split('.');
        if (this.isDash(value)) splitted = value.split('-');
        if (!splitted || splitted.length == 0) return null;
        if (splitted[0] == '' || splitted[1] == '' || splitted[2] == '') return null;
        const day = parseInt(splitted[0]);
        const month = parseInt(splitted[1]) - 1;
        const year = parseInt(splitted[2]);
        const date = new Date(year, month, day);
        return date;
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
    private isSlash(value: string) {
        return value.indexOf('/') > 0;
    }
    private isDot(value: string) {
        return value.indexOf('.') > 0;
    }
    private isDash(value: string) {
        return value.indexOf('-') > 0;
    }
    private isDate(value: string) {
        const dateregx = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
        return dateregx.test(value);
    }
}
