import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DayTimeSlotComponent } from './day-time-slot.component';
import { InputSelectBoxModule } from '../input-select-box';

const COMPONENTS = [DayTimeSlotComponent];

@NgModule({
  imports: [FormsModule, CommonModule, InputSelectBoxModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class DayTimeSlotModule {}
