import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IFileServerModel } from '@renovars/core-nest';
import { AppConfigService, FilesService } from '@renovars/core-ng';
import { filter, switchMap, tap } from 'rxjs';
import { ImageDirective } from '../core/directives/form.directive';
import { ConfirmModalComponent } from '../modals';
import { CarouselItemDirective } from './carousel.directive';
import { SortGalleryModalComponent } from './sort-gallery-modal/sort-gallery-modal.component';
@Component({
  selector: 'fullstack-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: [`./carousel.component.scss`],
})
export class CarouselComponent implements OnInit, AfterContentInit {
  @ContentChildren(ImageDirective) public imagesQueryList;
  @Input() accept = 'image/*';
  @Input() maxFileSize = 20000000;
  @Input() storageUrl;
  public selectedIndex = 0;
  @Output() upload = new EventEmitter();
  @Output() info = new EventEmitter();
  @Output() refresh = new EventEmitter<IFileServerModel[]>();
  @ContentChildren(CarouselItemDirective) items: QueryList<CarouselItemDirective>;
  itemsArray: CarouselItemDirective[];
  primeGalleryData: [
    { previewImageSrc: string; thumbnailImageSrc?: string; alt?: string; title?: string }?
  ] = [];
  displayBasic;

  constructor(
    private filesService: FilesService,
    private dialog: MatDialog,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.items.changes.subscribe((i) => {
      this.prepareImages();
    });
    this.prepareImages();
  }

  prepareImages() {
    this.itemsArray = this.items.toArray();
    this.itemsArray.forEach((i) =>
      this.primeGalleryData.push({
        previewImageSrc: `${this.appConfigService.getApiProxiedURL()}/file-server/download/${i.file.id}`,
      })
    );
  }

  _add(event) {
    this.upload.emit(event.target.files);
  }

  _delete(i: number) {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Confermi di voler eliminare questo elemento?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap(() => this.filesService.delete(this.itemsArray[i].file.id)),
        tap(() => this.itemsArray.splice(i, 1))
      )
      .subscribe();
  }

  download(i: number) {
    /* this.filesService.downloadAsAttachment(this.itemsArray[i].file.id); */
  }

  _info(i: number) {
    this.info.emit(this.itemsArray[i].file);
  }

  scrollRight(gallerySlideElem) {
    gallerySlideElem.scrollLeft += 100;
  }

  scrollLeft(gallerySlideElem) {
    gallerySlideElem.scrollLeft -= 100;
  }

  openSortModal() {
    if (this.itemsArray && this.itemsArray.length > 0) {
      this.dialog
        .open(SortGalleryModalComponent, {
          width: '50vw',
          data: { gallery: this.itemsArray, storageUrl: this.storageUrl },
        })
        .afterClosed()
        .pipe(filter((res) => res))
        .subscribe((res) => {
          this.filesService.sortBulk({ files: res }).subscribe((files: IFileServerModel[]) => {
            this.refresh.emit(files);
          });
        });
    }
  }
}
