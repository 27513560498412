export enum CofStateCodes {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
  HR_DA_CONTATTARE = 'HR_DA_CONTATTARE',
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_NON_RISPONDE = 'HR_NON_RISPONDE',
  HR_KO_CONTACT_DUPLICATED = 'HR_KO_CONTACT_DUPLICATED',
  HR_KO_CV = 'HR_KO_CV',
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO',
  HR_KO_NUM_ERRATO = 'HR_KO_NUM_ERRATO',

  HR_KO_DISTANZA = 'HR_KO_DISTANZA',
  HR_KO_GIA_LAVORA = 'HR_KO_GIA_LAVORA',
  HR_KO_SMARTWORKING = 'HR_KO_SMARTWORKING',
  HR_KO_NON_IN_LINEA = 'HR_KO_NON_IN_LINEA',
  HR_KO_NON_REPERIBILE = 'HR_KO_NON_REPERIBILE',

  HR_SM_COLLOQUIO_FISSATO = 'HR_SM_COLLOQUIO_FISSATO',
  HR_SM_COLLOQUIO_RIFISSATO = 'HR_SM_COLLOQUIO_RIFISSATO',
  HR_SM_ASSENTE = 'HR_SM_ASSENTE',

  SM_OK_COLLOQUIO_ESEGUITO = 'SM_OK_COLLOQUIO_ESEGUITO',
  SM_KO_COLLOQUIO_ESEGUITO = 'SM_KO_COLLOQUIO_ESEGUITO',

  SM_INVITO_AFFIACAMENTO = 'SM_INVITO_AFFIACAMENTO',
  SM_OK_AFFIANCAMENTO = 'SM_OK_AFFIANCAMENTO',
  SM_KO_AFFIANCAMENTO = 'SM_KO_AFFIANCAMENTO',

  SM_FORMAZIONE_FISSATA = 'SM_FORMAZIONE_FISSATA',
  SM_FORMAZIONE_RIFISSATA = 'SM_FORMAZIONE_RIFISSATA',
  SM_KO_POST_FORMAZIONE = 'SM_KO_POST_FORMAZIONE',
  SM_ABBANDONO_FORMAZIONE = 'SM_ABBANDONO_FORMAZIONE',
  SM_OPERATIVO = 'SM_OPERATIVO',
}
export enum CofStateLabel {
  ADMIN_ASSEGNATO = 'Assegnazione Automatica',
  HR_DA_CONTATTARE = 'Da Contattare',
  HR_DA_RICHIAMARE = 'Da Richiamare',
  HR_NON_RISPONDE = 'Non risponde',
  HR_KO_CONTACT_DUPLICATED = 'KO Contatto Duplicato',
  HR_KO_NUM_ERRATO = 'Numero errato',
  HR_KO_CV = 'KO CV',
  HR_KO_NO_INTERESSATO = 'KO Non Interessato',
  HR_KO_DISTANZA = 'KO Distanza',
  HR_KO_GIA_LAVORA = 'KO Già Lavora',
  HR_KO_SMARTWORKING = 'KO Smartworking',
  HR_KO_NON_IN_LINEA = 'KO Non In Linea',
  HR_KO_NON_REPERIBILE = 'KO Irreperibile',
  HR_SM_COLLOQUIO_FISSATO = 'Colloquio Fissato',
  HR_SM_COLLOQUIO_RIFISSATO = 'Colloquio Rifissato',
  HR_SM_ASSENTE = 'Assente',
  SM_OK_COLLOQUIO_ESEGUITO = 'Esito Colloquio OK',
  SM_KO_COLLOQUIO_ESEGUITO = 'Esito Colloquio KO',
  SM_INVITO_AFFIACAMENTO = 'Invito Affiancamento',
  SM_OK_AFFIANCAMENTO = 'OK Affiancamento',
  SM_KO_AFFIANCAMENTO = 'Abbandono Affiancamento',
  SM_FORMAZIONE_FISSATA = 'Formazione',
  SM_FORMAZIONE_RIFISSATA = 'Formazione Rifissata',
  SM_KO_POST_FORMAZIONE = 'KO Post Formazione',
  SM_ABBANDONO_FORMAZIONE = 'Abbandono Formazione',
  SM_OPERATIVO = 'Operativo',
}
export enum CofStateIcons {
  ADMIN_ASSEGNATO = 'pi pi-check-circle',
  HR_DA_CONTATTARE = 'pi pi-check-circle',
  HR_DA_RICHIAMARE = 'pi pi-check-circle',
  HR_NON_RISPONDE = 'pi pi-ban',
  HR_KO_CONTACT_DUPLICATED = 'pi pi-ban',
  HR_KO_NUM_ERRATO = 'pi pi-ban',
  HR_KO_CV = 'pi pi-ban',
  HR_KO_NO_INTERESSATO = 'pi pi-ban',
  HR_KO_DISTANZA = 'pi pi-ban',
  HR_KO_GIA_LAVORA = 'pi pi-ban',
  HR_KO_SMARTWORKING = 'pi pi-ban',
  HR_KO_NON_IN_LINEA = 'pi pi-ban',
  HR_KO_NON_REPERIBILE = 'pi pi-ban',
  HR_SM_COLLOQUIO_FISSATO = 'pi pi-calendar',
  HR_SM_COLLOQUIO_RIFISSATO = 'pi pi-calendar',
  HR_SM_ASSENTE = 'pi pi-ban',
  SM_OK_COLLOQUIO_ESEGUITO = 'pi pi-check-circle',
  SM_KO_COLLOQUIO_ESEGUITO = 'pi pi-ban',
  SM_INVITO_AFFIACAMENTO = 'pi pi-calendar',
  SM_OK_AFFIANCAMENTO = 'pi pi-check-circle',
  SM_KO_AFFIANCAMENTO = 'pi pi-ban',
  SM_FORMAZIONE_FISSATA = 'pi pi-calendar',
  SM_FORMAZIONE_RIFISSATA = 'pi pi-calendar',
  SM_KO_POST_FORMAZIONE = 'pi pi-ban',
  SM_ABBANDONO_FORMAZIONE = 'pi pi-ban',
  SM_OPERATIVO = 'pi pi-check-circle',
}

const hrDef = {
  HR_KO_CONTACT_DUPLICATED: [CofStateCodes.HR_DA_RICHIAMARE],
  HR_DA_CONTATTARE: [
    CofStateCodes.HR_KO_CV,
    CofStateCodes.HR_KO_NO_INTERESSATO,
    CofStateCodes.HR_KO_DISTANZA,
    CofStateCodes.HR_KO_GIA_LAVORA,
    CofStateCodes.HR_KO_SMARTWORKING,
    CofStateCodes.HR_KO_NON_IN_LINEA,
    CofStateCodes.HR_SM_COLLOQUIO_FISSATO,
    CofStateCodes.HR_DA_RICHIAMARE,
    CofStateCodes.HR_NON_RISPONDE,
    CofStateCodes.HR_KO_NUM_ERRATO,
  ],
  HR_NON_RISPONDE: [CofStateCodes.HR_DA_RICHIAMARE], //
  HR_KO_CV: [CofStateCodes.HR_SM_COLLOQUIO_FISSATO],
  HR_KO_NO_INTERESSATO: [CofStateCodes.HR_SM_COLLOQUIO_FISSATO],
  HR_KO_DISTANZA: [CofStateCodes.HR_SM_COLLOQUIO_FISSATO],
  HR_KO_GIA_LAVORA: [CofStateCodes.HR_SM_COLLOQUIO_FISSATO],
  HR_KO_SMARTWORKING: [CofStateCodes.HR_SM_COLLOQUIO_FISSATO],
  HR_KO_NON_IN_LINEA: [CofStateCodes.HR_SM_COLLOQUIO_FISSATO],
  HR_KO_NON_REPERIBILE: [CofStateCodes.HR_DA_RICHIAMARE],
  HR_DA_RICHIAMARE: [
    CofStateCodes.HR_KO_CV,
    CofStateCodes.HR_KO_NO_INTERESSATO,
    CofStateCodes.HR_KO_DISTANZA,
    CofStateCodes.HR_KO_GIA_LAVORA,
    CofStateCodes.HR_KO_SMARTWORKING,
    CofStateCodes.HR_KO_NON_IN_LINEA,
    CofStateCodes.HR_SM_COLLOQUIO_FISSATO,
    CofStateCodes.HR_NON_RISPONDE,
    CofStateCodes.HR_DA_RICHIAMARE,
    CofStateCodes.HR_KO_NON_REPERIBILE,
  ],
  HR_SM_COLLOQUIO_FISSATO: [
    CofStateCodes.HR_SM_ASSENTE,
    CofStateCodes.SM_KO_COLLOQUIO_ESEGUITO,
    CofStateCodes.SM_OK_COLLOQUIO_ESEGUITO,
  ],
  HR_SM_ASSENTE: [CofStateCodes.HR_SM_COLLOQUIO_RIFISSATO],
  SM_KO_COLLOQUIO_ESEGUITO: [CofStateCodes.HR_SM_COLLOQUIO_RIFISSATO],
  HR_SM_COLLOQUIO_RIFISSATO: [
    CofStateCodes.HR_SM_ASSENTE,
    CofStateCodes.SM_KO_COLLOQUIO_ESEGUITO,
    CofStateCodes.SM_OK_COLLOQUIO_ESEGUITO,
  ],
};
const smDef = {
  HR_SM_COLLOQUIO_FISSATO: [
    CofStateCodes.HR_SM_ASSENTE,
    CofStateCodes.SM_KO_COLLOQUIO_ESEGUITO,
    CofStateCodes.SM_OK_COLLOQUIO_ESEGUITO,
  ],
  HR_SM_COLLOQUIO_RIFISSATO: [
    CofStateCodes.HR_SM_ASSENTE,
    CofStateCodes.SM_KO_COLLOQUIO_ESEGUITO,
    CofStateCodes.SM_OK_COLLOQUIO_ESEGUITO,
  ],
  HR_SM_ASSENTE: [
    CofStateCodes.HR_SM_COLLOQUIO_RIFISSATO,
    CofStateCodes.SM_KO_COLLOQUIO_ESEGUITO,
    CofStateCodes.SM_OK_COLLOQUIO_ESEGUITO,
  ],
  SM_OK_COLLOQUIO_ESEGUITO: [CofStateCodes.SM_KO_COLLOQUIO_ESEGUITO, CofStateCodes.SM_INVITO_AFFIACAMENTO],
  SM_KO_COLLOQUIO_ESEGUITO: [CofStateCodes.HR_SM_COLLOQUIO_RIFISSATO, CofStateCodes.SM_OK_COLLOQUIO_ESEGUITO],
  SM_INVITO_AFFIACAMENTO: [CofStateCodes.SM_OK_AFFIANCAMENTO, CofStateCodes.SM_KO_AFFIANCAMENTO],
  SM_OK_AFFIANCAMENTO: [CofStateCodes.SM_FORMAZIONE_FISSATA],
  SM_KO_AFFIANCAMENTO: [],
  SM_FORMAZIONE_FISSATA: [
    CofStateCodes.SM_KO_POST_FORMAZIONE,
    CofStateCodes.SM_ABBANDONO_FORMAZIONE,
    CofStateCodes.SM_OPERATIVO,
  ],
  SM_FORMAZIONE_RIFISSATA: [
    CofStateCodes.SM_KO_POST_FORMAZIONE,
    CofStateCodes.SM_ABBANDONO_FORMAZIONE,
    CofStateCodes.SM_OPERATIVO,
  ],
  SM_KO_POST_FORMAZIONE: [],
  SM_ABBANDONO_FORMAZIONE: [CofStateCodes.SM_FORMAZIONE_RIFISSATA],
  SM_OPERATIVO: [],
};
const CofStatesDefinition = {
  ADMIN: {
    ADMIN_ASSEGNATO: [CofStateCodes.HR_DA_CONTATTARE, CofStateCodes.HR_KO_CONTACT_DUPLICATED],
    ...hrDef,
    ...smDef,
  },
  HR: hrDef,
  SM: smDef,
};

export function getAllCofHRStates() {
  return Object.keys(CofStatesDefinition.HR);
}
export function getAllCofSMStates() {
  return Object.keys(CofStatesDefinition.SM);
}
export function getAllCofStates() {
  return getAllCofHRStates().concat(getAllCofSMStates());
}

export function getPossibleChoiseStates(
  currentState: CofStateCodes,
  role: 'SM' | 'HR' | 'ADMIN'
): CofStateCodes[] {
  switch (role) {
    case 'HR':
      return CofStatesDefinition.HR[currentState] || [];
    case 'SM':
      return CofStatesDefinition.SM[currentState] || [];
    case 'ADMIN':
      return CofStatesDefinition.ADMIN[currentState] || [];
    default:
      return [];
  }
}

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}
