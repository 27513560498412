import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CommonRoles,
  FileServerGroupType,
  IObjectList,
  IUser,
  IUserMeta,
  mergeArrayCustomizer,
} from '@renovars/common';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '../features';
import { UtentiModuleOptions, UTENTI_CONFIG_PROVIDER } from '../features/utenti/module.options';
import { cacheResponse } from '../utils/cache.utils';
import { FilesService } from './files.service';
import sha256 from 'crypto-js/sha256';

@Injectable()
export class UsersService {
  constructor(
    private http: HttpClient,
    private filesService: FilesService,
    @Inject(UTENTI_CONFIG_PROVIDER) private config: UtentiModuleOptions,
    private messageService: MessageService,
    private appConfigService: AppConfigService
  ) {}

  getUserMeta(id): Observable<IUserMeta> {
    return this.http.get<any>(`${this.config.userMetaEndpoint}/by-user-id/${id}`);
  }

  saveUserMeta(obj: IUserMeta): Observable<any> {
    return this.http.post<any>(`${this.config.userMetaEndpoint}`, obj);
  }

  updateUserMeta(id: string, obj: IUserMeta): Observable<any> {
    return this.getUserMeta(id).pipe(
      map((res) => {
        const payload: IUserMeta = _.cloneDeep(_.mergeWith({}, res, obj, mergeArrayCustomizer));
        return payload;
      }),
      switchMap((payload) => this.http.put<any>(`${this.config.userMetaEndpoint}/${id}`, payload)),
      tap(() =>
        this.messageService.add({
          severity: 'success',
          summary: 'Utenti',
          detail: 'Utente modificato correttamente',
        })
      )
    );
  }

  uploadProfileImage(userId: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);

    return this.filesService
      .uploadFile(file)
      .pipe(
        switchMap((res) =>
          this.filesService.update(res[0], { userId: userId, group: FileServerGroupType.USER_IMAGE })
        )
      );
  }

  deleteProfileImage(fileId: string): Observable<any> {
    return this.filesService.delete(fileId);
  }

  getProfileImage(userId: string) {
    return this.filesService.search({ userId: userId, group: FileServerGroupType.USER_IMAGE });
  }

  getUsersListDownline(
    excludeRolesFromNetwork: string[],
    role: string[],
    userId?: string
  ): Observable<IUser[]> {
    let params = {
      excludeRolesFromNetwork,
      role,
      userId,
    };
    return cacheResponse(
      sha256(JSON.stringify(params)),
      this.http
        .post<IObjectList>(
          `${this.appConfigService.getApiProxiedURL()}/user-meta/getUsersListDownlineFromFusion`,
          params
        )
        .pipe(map((res) => res.data))
    );
  }

  getUsersBySite(site: string): Observable<IUserMeta[]> {
    return this.http.post<any>(`${this.config.userMetaEndpoint}/getUsersBySite`, { site });
  }

  getUsersByArea(area: number): Observable<IUserMeta[]> {
    return this.http.post<any>(`${this.config.userMetaEndpoint}/getUsersByArea`, { area });
  }

  getUsers(): Observable<IUser[]> {
    return this.http
      .post<IObjectList>(
        `${this.appConfigService.getApiProxiedURL()}/user-meta/getUsersListDownlineFromFusion`,
        { excludeRolesFromNetwork: [CommonRoles.IS_ADMIN] }
      )
      .pipe(
        map((res) => res.data),
        map((list: IUser[]) => {
          const toSorT = [...list];
          toSorT.sort((a: IUser, b: IUser) =>
            a.lastName && b.lastName ? a?.lastName.localeCompare(b?.lastName) : 0
          );
          return toSorT;
        })
      );
  }

  excludeNetworkUsers$(roles: string[]): Observable<IUser[]> {
    return this.http
      .post<IObjectList>(
        `${this.appConfigService.getApiProxiedURL()}/user-meta/getUsersListDownlineFromFusion`,
        { excludeRolesFromNetwork: [CommonRoles.IS_ADMIN, ...roles] }
      )
      .pipe(
        map((res) => res.data),
        map((list: IUser[]) => {
          let toSorT = [...list];
          toSorT.sort((a: IUser, b: IUser) =>
            a.lastName && b.lastName ? a?.lastName.localeCompare(b?.lastName) : 0
          );
          return toSorT;
        })
      );
  }

  getUser(id): Observable<IUser> {
    return this.http.get<IUser>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/findOne/${id}`);
  }

  saveUser(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${this.appConfigService.getApiProxiedURL()}/fusionauth`, user);
  }

  updateUser(user: IUser, id: string): Observable<IUser> {
    return this.http.put<IUser>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/${id}`, user);
  }

  deleteUser(id: string): Observable<void> {
    return this.http.delete<void>(`${this.appConfigService.getApiProxiedURL()}/fusionauth/${id}`);
  }

  activateBC(id: string): Observable<IUser> {
    return this.http.post<IUser>(`${this.appConfigService.getApiProxiedURL()}/projects/activateAgentBC`, {
      user: id,
    });
  }
}
