import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  Output,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { FormElement } from '../core/utils/form-element';
import { DatepickerHeaderComponent } from './datepicker-header.component';

@Injectable()
export class LocalDateAdapter extends NativeDateAdapter {
  constructor(@Inject(LOCALE_ID) public locale: string, @Inject(PLATFORM_ID) platformId: never) {
    super(locale, platformId);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'fullstack-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerComponent),
      multi: true,
    },
    { provide: DateAdapter, useClass: LocalDateAdapter },
  ],
})
export class DaterangepickerComponent extends FormElement {
  @Input() options: { label: string; placeholder: string; min?: Date; max?: Date };
  @Output() rangeChange: EventEmitter<unknown> = new EventEmitter();
  start: Date;
  end: Date;
  readonly DatepickerHeaderComponent = DatepickerHeaderComponent;

  _rangeChange() {
    if (this.end) {
      this.rangeChange.emit({
        start: moment(this.start).startOf('day').toDate(),
        end: moment(this.end).endOf('day').toDate(),
      });
      if (this._onChange)
        this._onChange({
          end: moment(this.end).endOf('day').toDate(),
          start: moment(this.start).startOf('day').toDate(),
        });
    }
  }

  writeValue(obj: { start: Date; end: Date }): void {
    if (obj) {
      this.start = obj.start;
      this.end = obj.end;
    }
  }

  _clear() {
    this.start = undefined;
    this.end = undefined;
    this.rangeChange.emit();
  }
}
