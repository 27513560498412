import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputNumberComponent } from './input-number.component';

import { InputNumberModule as PrimeNGNumberModule } from 'primeng/inputnumber';

@NgModule({
  imports: [FormsModule, CommonModule, PrimeNGNumberModule],
  declarations: [InputNumberComponent],
  exports: [InputNumberComponent],
})
export class InputNumberModule {}
