import { Component } from '@angular/core';

@Component({
  selector: 'facile-site-page',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Sedi</h1>
    </ng-template>
    <router-outlet></router-outlet>
  `,
  styles: [],
})
export class SitePageComponent {}
