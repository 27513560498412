import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable()
export class CarouselServices {
  constructor(private http: HttpClient) {}

  uploadFiles(uploadApiUrl, files: File[]) {
    const upload$: Observable<string>[] = [];
    files.map((file: File) => {
      const formData: FormData = new FormData();
      formData.append('media', file, file.name);
      upload$.push(
        this.http.post<{ object: string; filename: string }>(`${uploadApiUrl}`, formData).pipe(
          map((res) => res.filename),
          catchError((error) => of(null))
        )
      );
    });
    return forkJoin(upload$);
  }
}
