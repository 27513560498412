import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@renovars/ui-ng';
import { MenuModule } from 'primeng/menu';
import { TreeTableModule } from 'primeng/treetable';
import { NetworkPageComponent } from './components/pages/network-page.component';
import { NetworkSummaryPageComponent } from './components/pages/summary/summary.component';
import { NetworkModuleOptions, NETWORK_CONFIG_PROVIDER } from './module.options';
import { GetRolesPipe } from './pipes/utils.pipe';
import { NetworkService } from '../../services/network.service';

const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full',
      },
      {
        path: '',
        component: NetworkPageComponent,
        children: [{ path: 'summary', component: NetworkSummaryPageComponent }],
      },
    ],
  },
];
@NgModule({
  declarations: [NetworkPageComponent, NetworkSummaryPageComponent, GetRolesPipe],
  imports: [RouterModule.forChild(ROUTES), UiModule, MenuModule, TreeTableModule],
  providers: [NetworkService],
  exports: [],
})
export class NetworkModule {
  static forRoot(config: NetworkModuleOptions): ModuleWithProviders<NetworkModule> {
    return {
      ngModule: NetworkModule,
      providers: [
        {
          provide: NETWORK_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
