import { AfterContentInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderService } from './header.service';
@Component({
  selector: 'fullstack-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterContentInit {
  @Input() title;

  constructor(public headerService: HeaderService) {}

  ngOnInit(): void {}

  openTimeline() {}

  ngAfterContentInit(): void {}
}
