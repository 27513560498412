<renovars-custom-range-panel></renovars-custom-range-panel>

<div class="example-header">
    <!-- "previous" buttons -->
    <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
        <i class="fas fa-chevron-left"></i>
        <i class="fas fa-chevron-left"></i>
    </button>
    <button mat-icon-button (click)="previousClicked('month')">
        <i class="fas fa-chevron-left"></i>
    </button>

    <!-- center label -->
    <span class="example-header-label">{{ periodLabel }}</span>

    <!-- "next" buttons -->
    <button mat-icon-button (click)="nextClicked('month')">
        <i class="fas fa-chevron-right"></i>
    </button>
    <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
        <i class="fas fa-chevron-right"></i>
        <i class="fas fa-chevron-right"></i>
    </button>
</div>