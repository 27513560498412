<div class="fullstack-datatable">
  <p-contextMenu #cm [model]="cmItems" appendTo="body"></p-contextMenu>

  <p-table
    #table
    selectionMode="single"
    [value]="list"
    [contextMenu]="cmItems && cmItems.length ? cm : null"
    [contextMenuSelection]="selectedItem"
    (contextMenuSelectionChange)="onSelect($event)"
    [selection]="selectedItem"
    (selectionChange)="onSelect($event)"
    [lazy]="options?.lazy"
    (onLazyLoad)="onLazyLoad($event)"
    [paginator]="paginator"
    [rows]="maxRows"
    [first]="first"
    [rowsPerPageOptions]="[5, 10, 25, 50, 75, 100]"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [selection]="selection"
    currentPageReportTemplate="Da {first} a {last} di {totalRecords} risultati"
    [styleClass]="stileClass"
    [autoLayout]="true"
    [globalFilterFields]="globalFilterFields"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [scrollable]="true"
    [scrollHeight]="scrollHeight"
    [frozenColumns]="frozenColumns"
    [frozenValue]="[{ storeManager: 1 }]"
    [columns]="headerTemplate?.columns?._results"
  >
    <ng-template pTemplate="caption" *ngIf="clientExport">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-secondary btn-xs matt rounded"
          (click)="table.exportCSV()"
          pTooltip="CSV"
          tooltipPosition="bottom"
        >
          CSV
        </button>
      </div>
    </ng-template>

    <ng-template *ngIf="globalFilterFields" pTemplate="caption">
      <div class="text-end">
        <input
          pInputText
          type="text"
          (input)="table.filterGlobal($event.target.value, 'contains')"
          placeholder="Cerca..."
        />
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let c of headerTemplate.columns"
          pSortableColumn="{{ c.field }}"
          pFrozenColumn
          [frozen]="isFrozen(c)"
          [ngStyle]="{ 'min-width': c.width || '230px' }"
        >
          <div class="header-table">
            <div><ng-container *ngTemplateOutlet="c.title?.templateRef"></ng-container></div>
            <p-columnFilter
              *ngIf="c.clientFilter && c.field"
              type="text"
              field="{{ c.field }}"
              display="menu"
            ></p-columnFilter>

            <p-sortIcon *ngIf="c.field" field="{{ c.field }}"></p-sortIcon>
          </div>
        </th>
      </tr>
      <tr>
        <ng-container *ngFor="let c of headerTemplate.columns">
          <th class="p-0" [ngClass]="{ 'p-1': c.filter }" pFrozenColumn [frozen]="isFrozen(c)">
            <ng-container *ngTemplateOutlet="c.filter?.templateRef"></ng-container>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pContextMenuRow]="row" [pSelectableRow]="row" [ngStyle]="{ 'background-color': row?._color }">
        <ng-container *ngTemplateOutlet="bodyTemplate.templateRef; context: { $implicit: row, rowIndex: i }">
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
