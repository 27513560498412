import { ModuleWithProviders, NgModule } from '@angular/core';
import { DomainValuesOptions, DOMAIN_VALUES_CONFIG_PROVIDER } from './module.options';
import { DomainValuesFilterPipe, DomainValuesPipe } from './pipes/domain-values.pipe';
import { DomainValuesService } from '../../services/domain-values.service';

@NgModule({
  declarations: [DomainValuesFilterPipe, DomainValuesPipe],
  imports: [],
  exports: [DomainValuesFilterPipe, DomainValuesPipe],
})
export class DomainValuesModule {
  static forRoot(config: DomainValuesOptions): ModuleWithProviders<DomainValuesModule> {
    return {
      ngModule: DomainValuesModule,
      providers: [
        DomainValuesService,
        {
          provide: DOMAIN_VALUES_CONFIG_PROVIDER,
          useValue: config,
        },
        DomainValuesPipe,
      ],
    };
  }
}
