<div style="height: 100%; box-sizing: border-box">
  <renovars-file-manager
    [fileElements]="fileElements | async"
    [path]="currentPath"
    [canNavigateUp]="canNavigateUp"
    (folderAdded)="addFolder($event)"
    (fileAdded)="addFile($event)"
    (elementRemoved)="removeElement($event)"
    (navigatedDown)="navigateToFolder($event)"
    (navigatedUp)="navigateUp()"
    (elementRenamed)="renameElement($event)"
    (elementMoved)="moveElement($event)"
    (viewRoleAdded)="setViewRole($event)"
  ></renovars-file-manager>
</div>
