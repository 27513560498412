import * as _ from 'lodash';

export function mergeArrayCustomizer(objValue, srcValue, key) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}

export function flatten(obj, prefix?) {
  var propName = prefix ? prefix + '.' : '',
    ret = {};

  for (var attr in obj) {
    if (_.isArray(obj[attr])) {
      var len = obj[attr].length;
      ret[attr] = obj[attr].join(',');
    } else if (typeof obj[attr] === 'object') {
      _.extend(ret, flatten(obj[attr], propName + attr));
    } else {
      ret[propName + attr] = obj[attr];
    }
  }
  return ret;
}

export function flattenMongo(obj, prefix?) {
  var propName = prefix ? prefix + '.' : '',
    ret = {};

  Object.keys(obj).forEach((attr) => {
    if (obj[attr] == null) {
      ret[propName + attr] = null;
    } else if (_.isArray(obj[attr])) {
      ret[propName + attr] = obj[attr];
    } else if (typeof obj[attr] === 'object') {
      _.extend(ret, flattenMongo(obj[attr], propName + attr));
    } else {
      ret[propName + attr] = obj[attr];
    }
  });
  return ret;
}

export const unflatten = (obj = {}) => {
  const result = {};
  let temp, substrings, property, i;
  for (property in obj) {
    substrings = property.split('.');
    temp = result;
    for (i = 0; i < substrings.length - 1; i++) {
      if (!(substrings[i] in temp)) {
        if (isFinite(substrings[i + 1])) {
          temp[substrings[i]] = [];
        } else {
          temp[substrings[i]] = {};
        }
      }
      temp = temp[substrings[i]];
    }
    temp[substrings[substrings.length - 1]] = obj[property];
  }
  return result;
};
