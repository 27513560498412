import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputToggleComponent } from './input-toggle.component';

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: [InputToggleComponent],
  exports: [InputToggleComponent],
})
export class InputToggleModule {}
