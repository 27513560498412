import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ImageDirective } from '../core/directives/form.directive';
import { CarouselComponent } from './carousel.component';
import { CarouselItemDirective } from './carousel.directive';
import { CarouselServices } from './carousel.services';
import { GalleriaModule } from 'primeng/galleria';
import { SortGalleryModalComponent } from './sort-gallery-modal/sort-gallery-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';

const COMPONENTS = [CarouselComponent, ImageDirective, CarouselItemDirective, SortGalleryModalComponent];

@NgModule({
  imports: [FormsModule, CommonModule, GalleriaModule, DragDropModule, MatDialogModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [CarouselServices],
})
export class CarouselModule {}
