<fullstack-page-layout [registration]="(user$ | async)?.registrations">
  <div sidebar>
    <fullstack-sidebar-menu
      [options]="{
        logoUrl: 'assets/img/logo.png',
        logoMinUrl: 'assets/img/logo-min.png'
      }"
      [user]="(user$ | async)?.given_name + ' ' + (user$ | async)?.family_name"
      (logout)="logout()"
    >
      <sidebar-item [label]="'Utenti'" [url]="'./users'" *permissions="['user_manager']" [icon]="'fa-users'">
        <sidebar-item [label]="'Lista'" [url]="'list'"></sidebar-item>
      </sidebar-item>
      <!-- <sidebar-item
        [label]="'Anagrafica'"
        [url]="'./contacts'"
        *permissions="['contact_manager']"
        [icon]="'fa-id-card'"
      ></sidebar-item> -->
      <sidebar-item [label]="'Dashboard'" [url]="'./dashboard'" [icon]="'fa-chart-line'"></sidebar-item>
      <sidebar-item [label]="'Fonti'" [url]="'./dashboard/source'" [icon]="'fa-chart-bar'"></sidebar-item>
      <sidebar-item
        [label]="'Operatore Telefonico'"
        [url]="'./candidati'"
        *permissions="['is_admin', 'candidate_manager']"
        [icon]="'fa-user-tie'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Network'"
        [url]="'./network'"
        *permissions="['network_manager']"
        [icon]="'fa-project-diagram'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Appuntamenti'"
        [url]="'./events'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-alt'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Calendario'"
        [url]="'./calendar'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-day'"
      ></sidebar-item>
    </fullstack-sidebar-menu>
  </div>
  <div content>
    <router-outlet></router-outlet>
  </div>
</fullstack-page-layout>
