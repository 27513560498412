import { NgModule } from '@angular/core';
import { ArrayJoinPipe } from './array-join.pipe';
import { ContactEmailPipe } from './contact-email.pipe';
import { ContactNamePipe } from './contact-name.pipe';
import { ContactPhonePipe } from './contact-phone.pipe';
import { GetLabelPipe } from './get-label.pipe';
import { HistoryDatesPipe } from './history-dates.pipe';
const DECLARATIONS = [
  GetLabelPipe,
  ArrayJoinPipe,
  ContactEmailPipe,
  ContactPhonePipe,
  ContactNamePipe,
  HistoryDatesPipe,
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class PipeLibsModule {}
