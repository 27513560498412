export const SITE_OPTIONS = 'SITE_OPTIONS';

export interface ISiteOptions {
  url: string;
  exclude?: {
    bcCode?: boolean;
    phone?: boolean;
    area?: boolean;
  };
}
