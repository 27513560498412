import { Component, Inject, OnInit } from '@angular/core';
import { CommonRoles, LabelRoles } from '@renovars/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'renovars-new-file-dialog',
  template: `
    <p class="font-bold">Ruoli</p>
    <ul>
      <li *ngFor="let role of possibleRoles" (click)="addRole(role)">
        {{ labels[role] }} <i class="pi pi-plus"></i>
      </li>
    </ul>
    <p class="font-bold my-2">Visibile da</p>
    <ul>
      <li *ngFor="let item of elementActiveRoles" (click)="removeRole(item)">
        {{ labels[item] }} <i class="pi pi-times"></i>
      </li>
    </ul>
    <div class="row mt-4" style="display: flex; justify-content: end;">
      <div class="col offset-8">
        <button pButton (click)="onSave()" class="p-button-sm">Salva</button>
      </div>
    </div>
  `,
  styles: [],
})
export class SetVisibilityDialogComponent implements OnInit {
  selectedRoles: string[];
  possibleRoles: string[];
  labels = LabelRoles;
  elementActiveRoles: string[];
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    @Inject('FILE_M_CONF') public env: { endpoint: string; roles: Record<string, string> }
  ) {}

  ngOnInit(): void {
    this.elementActiveRoles = this.config.data.canBeViewedBy || [];
    this.possibleRoles = Object.values(this.env.roles)
      .filter((r) => (this.labels[r] ? true : false))
      .filter((r) => !this.elementActiveRoles.includes(r))
      .filter((r) => r !== CommonRoles.IS_ADMIN);
  }

  onSave() {
    this.ref.close(this.elementActiveRoles);
  }
  addRole(role: string) {
    const i = this.possibleRoles.indexOf(role);
    this.possibleRoles.splice(i, 1);
    this.elementActiveRoles.push(role);
  }
  removeRole(role: string) {
    const i = this.elementActiveRoles.indexOf(role);
    this.elementActiveRoles.splice(i, 1);
    this.possibleRoles.push(role);
  }
}
