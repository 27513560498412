import { NgModule } from '@angular/core';
import { FormsModule } from '../forms/forms.module';
import { ModalsModule } from '../modals/modals.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared.module';

const DECLARATIONS = [];

@NgModule({
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS, SharedModule, FormsModule, ModalsModule, PipesModule],
})
export class FeauturesSharedModule {}
