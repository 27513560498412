import { NgModule } from '@angular/core';
import { TimelineComponent } from './timeline.component';
import { TimelineModule as TimelinePrimeNgModule } from 'primeng/timeline';
import { TimelineContentDirective, TimelineMarkerDirective, TimelineOppositeDirective } from './directives';
import { CommonModule } from '@angular/common';

const COMPONENTS = [
  TimelineComponent,
  TimelineMarkerDirective,
  TimelineContentDirective,
  TimelineOppositeDirective,
];

@NgModule({
  imports: [CommonModule, TimelinePrimeNgModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class TimelineModule {}
