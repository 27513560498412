import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { SpinnerModule } from '../spinner';
import { ApplicationsMenuComponent } from './applications-menu/applications-menu.component';
import { HeaderComponent } from './header/header.component';
import { HeaderBodyDirective, HeaderMenuDirective } from './header/header.directives';
import { HeaderService } from './header/header.service';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { SidebarItemDirective } from './sidebar-menu/sidebar-menu.directive';
import { TabsComponent } from './tabs/tabs.component';
import { TabDirective } from './tabs/tabs.directive';
import { TimelineComponent } from './timeline/timeline.component';
import { TimelineItemsDirective } from './timeline/timeline.directives';

const COMPONENTS = [
  HeaderComponent,
  PageLayoutComponent,
  SidebarMenuComponent,
  TabsComponent,
  TimelineComponent,
  ApplicationsMenuComponent,
  TabDirective,
  HeaderBodyDirective,
  HeaderMenuDirective,
  SidebarItemDirective,
  TimelineItemsDirective,
];

@NgModule({
  imports: [RouterModule, FormsModule, CommonModule, ToastModule, SpinnerModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [HeaderService],
})
export class LayoutModule {}
