<div class="row m-2 gy-2">
  <div
    class="col-2"
    [pTooltip]="'Puoi filtrare gli eventi in base ad un singolo utente oppure per la rete specificata'"
  >
    <fullstack-input-select-box
      [options]="{ label: 'Ricerca per utente' }"
      [ngModel]="paginateRequest.form.userId"
      (ngModelChange)="searchByUser($event)"
    >
      <sb-option [key]="'id'" [value]="i" *ngFor="let i of storeManagers$ | async"
        >{{ i.fullName || i.firstName + ' ' + i.lastName }}</sb-option
      >
    </fullstack-input-select-box>
  </div>
  <div
    class="col-2"
    [pTooltip]="'Puoi filtrare gli eventi in base ad un singolo utente oppure per la rete specificata'"
  >
    <fullstack-input-select-box
      [options]="{ label: 'Network' }"
      [ngModel]="paginateRequest.form.network"
      (ngModelChange)="setNetworkFilter($event); loadAgentList($event)"
    >
      <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of networkList$ | async"
        >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
      >
    </fullstack-input-select-box>
  </div>

  <div class="col-3" [pTooltip]="'Digita il nominativo del contatto'">
    <fullstack-input-select-box
      [options]="{autocomplete : true, label: 'Ricerca per contatto'}"
      ngModel
      (ngModelChange)="paginateRequest.form.contactId = $event; search()"
      (inputChange)="searchContact($event)"
    >
      <sb-option [key]="'id'" [value]="i" *ngFor="let i of contacts$ | async">{{ i | contactName}}</sb-option>
    </fullstack-input-select-box>
  </div>
  <div class="col-3">
    <fullstack-input-box [options]="{ label: 'Indirizzo' }" (keyup.enter)="searchByAddress($event)">
    </fullstack-input-box>
  </div>
  <div class="col-2">
    <fullstack-input-select-box
      [options]="{ label: 'Tipo evento' }"
      ngModel
      (ngModelChange)="paginateRequest.form.eventType = $event; search()"
    >
      <sb-option [key]="'code'" [value]="i" *ngFor="let i of eventTypes">{{ i.label }}</sb-option>
    </fullstack-input-select-box>
  </div>
</div>
<div class="d-flex flex-row-reverse p-4">
  <button class="btn btn-secondary btn-sm" (click)="createPersonalEventClick()">
    <i class="fas fa-plus"></i> Evento personale
  </button>
</div>

<fullstack-fullcalendar
  (viewChange)="viewChange($event)"
  (eventClick)="onEventClick($event)"
  (dateClick)="onDateClick($event)"
>
  <div *ngFor="let e of list" f-event [event]="getEvent(e)"></div>
  <div
    *ngFor="let a of selectedUserAvailability"
    f-event
    [event]="{ title : 'Slot disponibile',
    daysOfWeek : [a.day],
    startTime : a.start.toString().padStart(2, '0') + ':00:00',
    endTime : a.end.toString().padStart(2, '0') + ':00:00',
    color : 'orange'} "
  ></div>
</fullstack-fullcalendar>
