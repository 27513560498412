import { Directive, HostBinding, Input, OnInit } from '@angular/core';
@Directive({
  selector: 'card-header',
})
export class CardHeaderDirective {
  @HostBinding('class')
  elementClass = 'card-header';
}

@Directive({
  selector: 'card-body',
})
export class CardBodyDirective {
  @HostBinding('class')
  elementClass = 'card-body';
}
@Directive({
  selector: 'board',
})
export class BoardDirective implements OnInit {
  @Input() public title: string;
  @Input() public list = [];
  @Input() public value;
  @Input() public disabled: boolean;

  ngOnInit(): void {
    this.disabled = this.disabled != undefined;
  }
}
