<div #select class="input-select-box dropdown" [class.disabled]="isDisabled">
  <label class="card card--form card--dropdown" [ngClass]="{ 'is-active': isActive }">
    <span class="card-body card-dropdown-toggle">
      <span class="card-dropdown__label">
        <span class="label-control">{{ options?.label }}</span>
        <small class="p-error block" *ngIf="error"> {{ error }}</small>
        <input
          class="form-control"
          type="text"
          [ngModel]="inputString"
          (ngModelChange)="inputString = $event; filterOptions(); openDropdown()"
          placeholder="{{ options?.placeholder }}"
          [disabled]="isDisabled"
        />
      </span>
      <span *ngIf="!isDisabled" class="card-dropdown__arrow"
        ><i class="fas fa-eraser" (click)="selectItem()"></i
      ></span>
      <span
        *ngIf="!isDisabled"
        class="card-dropdown__arrow"
        data-bs-toggle="dropdown"
        data-bs-flip="false"
        data-bs-reference="parent"
        data-bs-display="static"
      >
        <img src="assets/img/icn-angle-down.svg" alt="icon" />
      </span>
      <span class="card-dropdown dropdown-menu" style="width: 99%">
        <ul #dropdown class="list-group is-scroll">
          <li
            *ngFor="let so of filteredSelectOptions; let i = index"
            class="list-group-item"
            (click)="selectItem(i)"
          >
            <ng-container *ngIf="so.template">
              <ng-container *ngTemplateOutlet="so.template"></ng-container>
            </ng-container>
            <ng-container *ngIf="!so.template">{{ so.label }}</ng-container>
          </li>
        </ul>
      </span>
    </span>
  </label>
</div>
