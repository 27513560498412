<div *ngIf="value; else noAddress" class="d-flex flex-column h-100">
  <div class="flex-grow-1">
    <p-gmap
      [options]="options"
      [style]="{ display: 'block', width: '100%', height: '100%' }"
      (onMapReady)="setMap($event)"
    ></p-gmap>
  </div>

  <div>
    <!-- <form [formGroup]="form" (submit)="form.valid && geolocateNearPosition()" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="card card--form">
            <label class="card-body"
              ><span class="label-control">Indirizzo</span>
              <input
                class="form-control"
                type="text"
                placeholder="Indirizzo"
                formControlName="formatted_address"
              />
            </label>
          </div>
        </div>
      </div> -->
    <!-- <div class="row pt-2">
      <div class="col-12">
        <button class="btn btn-geo w-100" type="submit">
          <img class="btn-geo__pin" src="assets/img/icn-pin.svg" alt="pin" />Geocalizza la posizione più vicina
        </button>
      </div>
    </div> -->
    <!-- </form> -->
  </div>
</div>
<ng-template #noAddress>
  <p class="text-center">Non è stato impostato alcun indirizzo da visualizzare sulla mappa.</p>
</ng-template>
