import { NgModule } from '@angular/core';
import { FormDirective } from './directives/form.directive';
import { GmapsService } from './services/gmaps.service';

const COMPONENTS = [FormDirective];
@NgModule({
  declarations: COMPONENTS,
  imports: [],
  providers: [GmapsService],
  exports: COMPONENTS,
})
export class CoreModule {}
