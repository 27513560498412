import { ContentChild, ContentChildren, Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: '[t-filter]' })
export class DatatableFilterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: '[t-col-title]' })
export class DatatableColumnTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: '[t-column]' })
export class DatatableColumnDirective {
  @Input() field: any;
  @Input() clientFilter: any;
  @Input() width: string;
  @ContentChild(DatatableColumnTitleDirective) title;
  @ContentChild(DatatableFilterDirective) filter;

  constructor() {}
}
@Directive({ selector: '[t-header]' })
export class DatatableHeaderDirective {
  @ContentChildren(DatatableColumnDirective) columns;

  constructor() {}
}
@Directive({ selector: '[t-body]' })
export class DatatableBodyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}
