import { AfterContentInit, Component, ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'sb-option',
  template: `<ng-content></ng-content>`,
})
export class SelectBoxOptionDirective implements AfterContentInit {
  @Input() label: string;
  @Input() key: string;
  @Input() value: any;
  @ContentChild('optionTemplate')
  template: TemplateRef<any>;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit(): void {
    if (!this.template) this.label = this.elementRef.nativeElement.innerHTML;
  }
}
