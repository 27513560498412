import { AfterContentInit, ContentChildren, Directive, Input, QueryList } from '@angular/core';

@Directive({
  selector: 'sidebar-item',
})
export class SidebarItemDirective implements AfterContentInit {
  @Input() label: string;
  @Input() url?: string;
  @Input() icon?: string;
  @Input() externalLink: boolean;
  @ContentChildren(SidebarItemDirective) children?: QueryList<SidebarItemDirective>;
  subItems: SidebarItemDirective[];

  ngAfterContentInit(): void {
    this.subItems = this.children.toArray();
  }
}
