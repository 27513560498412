<h2 mat-dialog-title>Ordinamento delle foto</h2>

<mat-dialog-content class="mat-typography">
  <h3>
    A seguire trovi la lista delle foto relative all'immobile, puoi spostarle con il puntatore del mouse e
    quando hai finito cliccare sul pulsante "Salva".
  </h3>

  <div cdkDropList class="sortable__list" (cdkDropListDropped)="drop($event)">
    <div class="sortable__box" *ngFor="let image of images" cdkDrag>
      <div class="sortable__placeholder" *cdkDragPlaceholder></div>
      <img class="sortable__image" [src]="storageUrl + '/' + image._id" alt="images" />
      <div class="sortable__description">
        <div *ngIf="image.tag">
          <span class="badge badge-secondary">{{ image.tag }}</span>
        </div>
        <div *ngIf="image.metadata?.note">
          <span class="badge badge-dark">
            {{ image.metadata?.note }}
          </span>
        </div>
        <div *ngIf="image.metadata?.coverPhoto">
          <span class="badge badge-light"> Copertina </span>
        </div>
        <div *ngIf="image.metadata?.fimmFlag">
          <span class="badge badge-success"> Facileimmobiliare.it </span>
        </div>
        <div *ngIf="image.metadata?.immoitFlag">
          <span class="badge badge-info"> Immobiliare.it </span>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="btn btn-secondary rounded m-1" (click)="reorder()">Salva</button>
</mat-dialog-actions>
