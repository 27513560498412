import { Component, OnInit } from '@angular/core';
import {
  eventStateLabels,
  IAppointmentSearchDTO,
  IContact,
  IDomainValue,
  IEvent,
  IPaginateRequest,
  IPaginateResponse,
  IUser,
} from '@renovars/common';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { MenuItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ContactsServices } from '../../../../../../lib';
import { EventSharedService } from '../../../service/event-shared.service';
import { EventService } from '../../../../../services/event.service';
import appointmentTemplate from './appointment-list.template';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { FiRoles } from '@renovars/common/facileimmobiliare';

@Component({ template: '' })
export abstract class AppointmentListAbstractComponent implements OnInit {
  abstract gotoDetail();
  abstract gotoEdit();
  abstract gotoResult();

  paginateRequest: IPaginateRequest<IAppointmentSearchDTO> = { form: {} };
  domainValueKey = 'event_type';
  domainEventStateValueKey = 'event_state';
  domainSourceLeadTypeValueKey = 'source_lead_type';
  eventsList: IPaginateResponse<IEvent>;
  selected: IEvent;
  usersList$: Observable<IUser[]>;
  headerLess = false;
  eventTypes: IDomainValue<unknown>[] = [];
  eventStates: IDomainValue<unknown>[] = [];
  sourceLeadTypes: IDomainValue<unknown>[] = [];
  domainValuesList$: Observable<IDomainValue<unknown>[]>;
  cmItems: MenuItem[] = [];
  contacts$: Observable<IContact[]>;
  networkList$: Observable<IUser[]>;
  agentList$: Observable<IUser[]>;
  stateList = eventStateLabels;

  constructor(
    protected usersService: UsersService,
    protected eventService: EventService,
    private eventSharedService: EventSharedService,
    private contactsService: ContactsServices,
    private domainValuesService: DomainValuesService
  ) {}

  ngOnInit(): void {
    this.getUsersDownlineList();
    this.loadDomainValues();

    this.paginateRequest = this.eventSharedService.getPaginateRequest();
    this.paginateRequest.form.hidePersonalEvents = true;
  }

  getUsersDownlineList() {
    this.usersList$ = this.usersService.getUsersListDownline(
      [FiRoles.IS_ADMIN, FiRoles.IS_BACK_OFFICE, FiRoles.IS_PROPERTY_FINDER],
      null
    );
  }

  loadDomainValues() {
    this.domainValuesService.list().subscribe((res) => {
      if (res) {
        this.eventTypes = res.filter((d: IDomainValue<unknown>) => d.type === this.domainValueKey);
        this.eventStates = res.filter((d: IDomainValue<unknown>) => d.type === this.domainEventStateValueKey);
        this.sourceLeadTypes = res.filter(
          (d: IDomainValue<unknown>) => d.type === this.domainSourceLeadTypeValueKey
        );
        this.domainValuesList$ = of(res);
      }
    });
  }

  defineContextMenu() {
    this.cmItems = [
      {
        label: 'Modifica',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.gotoEdit();
        },
      },
      {
        label: 'Dettagli',
        icon: 'pi pi-fw pi-search',
        command: () => {
          this.gotoDetail();
        },
      },
      {
        label: 'Esito',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.gotoResult();
        },
      },
    ];
  }

  onLazyLoad(event) {
    this.search();
  }

  searchContact(searchString) {
    this.contacts$ = this.contactsService.searchByNominative(searchString).pipe(pluck('data'));
  }

  search() {
    this.eventService.search(this.paginateRequest).subscribe((res) => (this.eventsList = res));
  }

  loadAgentList(rootAgent) {
    this.agentList$ = this.usersService.getUsersListDownline(['is_admin'], null, rootAgent);
  }

  setNetworkFilter(value: string) {
    this.paginateRequest.form.network = value;
    this.search();
  }
}
@Component({
  selector: 'appointment-list',
  template: appointmentTemplate,
})
export class AppointmentListComponent extends AppointmentListAbstractComponent {
  gotoDetail() {
    throw new Error('Method not implemented.');
  }
  gotoEdit() {
    throw new Error('Method not implemented.');
  }
  gotoResult() {
    throw new Error('Method not implemented.');
  }
}
