import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { DatatableComponent } from './datatable.component';
import {
  DatatableBodyDirective,
  DatatableColumnDirective,
  DatatableColumnTitleDirective,
  DatatableFilterDirective,
  DatatableHeaderDirective,
} from './datatable.directive';

const COMPONENTS = [
  DatatableComponent,
  DatatableHeaderDirective,
  DatatableBodyDirective,
  DatatableColumnDirective,
  DatatableFilterDirective,
  DatatableColumnTitleDirective,
];
@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, FormsModule, TableModule, ContextMenuModule],
  exports: [...COMPONENTS],
  providers: [],
})
export class DatatableModule {}
