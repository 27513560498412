import { IsArray, IsIBAN, IsObject, IsOptional, IsString, ValidateIf } from 'class-validator';
import type { IAddress } from '../../addresses';
import { IEmail } from '../email.interface';
import { IPhone } from '../phone.interface';

export default class CreateContactIndividualDTO {
  @IsString()
  firstName: string;
  @IsString()
  @IsOptional()
  middleName: string;
  @IsString()
  lastName: string;
  @IsString()
  @IsOptional()
  birthDate: string;
  @IsString()
  @IsOptional()
  birthPlace: string;
  @IsString()
  @IsOptional()
  gender: string;
  @IsObject()
  address: IAddress;
  @IsObject()
  @IsOptional()
  billingAddress: IAddress;

  //@IsIBAN() //TODO: enable IBAN validator
  @IsString()
  @IsOptional()
  iban: string;
  @IsString()
  type: string;
  @IsString()
  @IsOptional()
  fiscalCode: string;
  @IsArray()
  @ValidateIf((o) => o.phones.length === 0 || o.emails.length === 0)
  phones: IPhone[];
  @IsArray()
  @ValidateIf((o) => o.phones.length === 0 || o.emails.length === 0)
  emails: IEmail[];
  @IsString()
  source: string;
  @IsString()
  application: string;
  @IsString()
  user: string;
}
