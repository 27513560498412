<h2 mat-dialog-title>Nuovo evento personale</h2>
<fullstack-radio-button #type [(ngModel)]="choice">
  <item [label]="'Giorno Singolo'" [value]="'single'"></item>
  <item [label]="'Giorni Multipli'" [value]="'multiple'"></item>
</fullstack-radio-button>
<mat-dialog-content class="mat-typography">
  <div class="row mb-2">
    <div class="col-12">
      <fullstack-input-box [options]="{ label: 'Descrizione' }" [(ngModel)]="note"></fullstack-input-box>
    </div>
  </div>

  <div class="row mb-2" *ngIf="choice === 'single'">
    <div class="col-12">
      <fullstack-datepicker [options]="{ label: 'Giorno' }" [(ngModel)]="date"> </fullstack-datepicker>
    </div>
  </div>

  <div class="row mb-2" *ngIf="choice === 'multiple'">
    <div class="col-12">
      <fullstack-daterangepicker
        [options]="{ label: 'Periodo' }"
        (rangeChange)="rangeChange($event)"
      ></fullstack-daterangepicker>
    </div>
  </div>

  <div class="row mb-3" *ngIf="choice === 'single'">
    <div class="col-6">
      <label for="">Dalle ore</label>
      <select class="form-select" [(ngModel)]="slot.start" (change)="checkStartRange()">
        <option *ngFor="let hour of slot.startHours; let i = index" [value]="hour | number">
          {{ hour }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <label for="">Alle ore</label>
      <select class="form-select" [(ngModel)]="slot.end" (change)="checkEndRange()">
        <option *ngFor="let hour of slot.endHours; let i = index" [value]="hour | number">
          {{ hour }}
        </option>
      </select>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button class="me-2" mat-dialog-close>Annulla</button>
  <button mat-raised-button color="primary" cdkFocusInitial (click)="save()" *ngIf="choice === 'single'">
    Conferma
  </button>
  <button
    mat-raised-button
    color="primary"
    cdkFocusInitial
    (click)="saveMultipleDay()"
    *ngIf="choice === 'multiple'"
  >
    Conferma
  </button>
</mat-dialog-actions>
