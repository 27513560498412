<header class="header" id="header">
  <div class="header__container">
    <div class="container-fluid mt-3">
      <div class="d-flex">
        <div
          *ngFor="let hb of headerService.headerBodyDirective; let i = index"
          [ngClass]="{ 'flex-grow-1': i == headerService.headerBodyDirective.length - 1 }"
        >
          <ng-container *ngTemplateOutlet="hb.templateRef"></ng-container>
        </div>
      </div>
    </div>

    <div class="mt-2 border-top" style="width: 100%">
      <div class="justify-content-between align-items-end">
        <div
          *ngFor="let hm of headerService.headerMenuDirective; let i = index"
          [ngClass]="{ 'col-auto': i == 0, col: i > 0 }"
        >
          <ng-container *ngTemplateOutlet="hm.templateRef"></ng-container>
        </div>
      </div>
    </div>
  </div>
</header>
