import { AfterContentInit, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import type { IAddress } from '@renovars/common';
import { IFormBuilder } from '@rxweb/types';
import { GmapsService } from '../core';
import { FormElement } from '../core/utils/form-element';

declare var google;

@Component({
  selector: 'fullstack-address-map-form',
  templateUrl: './address-map-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressMapFormComponent),
      multi: true,
    },
  ],
})
export class AddressMapFormComponent extends FormElement implements OnInit {
  options: any;
  map: any;
  overlays: any = [];
  markerImage: { url: string; scaledSize: any };
  form: UntypedFormGroup;
  fb: IFormBuilder = new UntypedFormBuilder();

  constructor(private gmapsService: GmapsService, private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group<IAddress>({
      route: new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
      street_number: new UntypedFormControl('', [Validators.required, Validators.maxLength(10)]),
      locality: new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]),
      postalCode: new UntypedFormControl('', []),
      province: new UntypedFormControl('', []),
      formatted_address: new UntypedFormControl('', []),
      co: new UntypedFormControl(null, []),
      coords: new UntypedFormControl(null, []),
      country: new UntypedFormControl(null, []),
      floor: new UntypedFormControl(null, []),
      radius: new UntypedFormControl(null, []),
      region: new UntypedFormControl(null, []),
      short_country: new UntypedFormControl(null, []),
    });
  }

  private initMap() {
    const mainMarker = 'assets/img/marker-1.svg';

    this.options = {
      center: { lat: 41.909986, lng: 12.3959159 },
      zoom: 8,
      styles: mainMarker,
    };

    this.markerImage = {
      url: mainMarker,
      scaledSize: new google.maps.Size(60, 71),
    };
  }

  setMarker(coords) {
    const bounds = new google.maps.LatLngBounds();
    const m = new google.maps.Marker({
      position: new google.maps.LatLng(coords[0], coords[1]),
      map: this.map,
    });
    this.overlays = [];
    this.overlays.push(
      new google.maps.Marker({
        position: {
          lat: coords[0],
          lng: coords[1],
        },
        title: '',
        icon: this.markerImage,
        data: {},
      })
    );

    if (this.overlays.length > 0) {
      this.overlays.forEach((marker) => {
        bounds.extend(marker.getPosition());
      });
    }

    this.map.setOptions(this.options);
    this.map.fitBounds(bounds);

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', function (event) {
      this.setZoom(15);
    });
  }

  setMap(event) {
    this.map = event.map;
    this.initMap();

    if (this.value) {
      this.form.patchValue(this.value);
      this.geolocateNearPosition();
    }

    this.changeDetector.detectChanges();
  }

  geolocateNearPosition() {
    const address: IAddress = this.form.getRawValue();
    this.gmapsService.geocoding(address.formatted_address).subscribe((r) => {
      this.value = r[0];

      const coords = this.value && this.value.coords ? this.value.coords : [41.909986, 12.3959161];
      this.setMarker(coords);
    });
  }
}
