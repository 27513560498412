import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SliderComponent } from './slider.component';
import { SliderModule } from 'primeng/slider';

const COMPONENTS = [SliderComponent];

@NgModule({
  imports: [FormsModule, CommonModule, SliderModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class FullstackSliderModule {}
