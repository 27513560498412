export interface IRole {
  description?: string;
  name: string;
  isDefault?: boolean;
  isSuperRole?: boolean;
}

export enum CommonRoles {
  IS_ADMIN = 'is_admin',
}
export const LabelRoles = {
  is_admin: 'Admin',
  is_store_manager: 'Store Manager',
  is_ufficio_hr: 'Hr',
  is_consumer_api: 'Consumer Api',
  is_director: 'Director',
  is_area_manager: 'Area manager',
  is_show_room_manager: 'Show Room Manager',
  is_direttore_commerciale: 'Direttore Commerciale',
  regional_manager: 'Regional Manager',
  is_agente_senior: 'Agente Senior',
  is_agente_junior: 'Agente Junior',
  is_agente_new: 'Agente New',
  is_callcenter: 'Callcenter',
  is_back_office: 'Backoffice',
  purchase_manager: 'Purchase Manager',
  //COF LABEL
  is_cc_manager: 'CC Manager',
  is_hr_operator: 'Operatore Telefonico',
  is_hr_jr: 'Hr jr',
};
