import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContact, IObjectList, IPaginateResponse } from '@renovars/common';
import type { IFilter } from '@renovars/mongoose-nest';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfigService } from '../features';
import { UtilsService } from './utils.services';

@Injectable()
export class ContactsServices {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private utilsService: UtilsService,
    private messageService: MessageService
  ) {}

  search(filter: IFilter): Observable<IPaginateResponse<IContact>> {
    filter = _.cloneDeep(filter);
    if (filter.where) {
      this.utilsService.setRegexCriteria(filter.where, 'firstName', filter.where.firstName);
      this.utilsService.setRegexCriteria(filter.where, 'lastName', filter.where.lastName);
      this.utilsService.setRegexCriteria(
        filter.where,
        'phones.0.phoneNumber',
        filter.where['phones.0.phoneNumber']
      );
      this.utilsService.setRegexCriteria(filter.where, 'emails.0.email', filter.where['emails.0.email']);
    }

    return this.http.post<IPaginateResponse<IContact>>(
      `${this.appConfigService.getApiGatewayURL()}/portale/contacts/search?extension=true`,
      filter
    );
  }

  searchByNominative(nominative: string) {
    if (!_.isEmpty(nominative)) {
      let nominativeSplit = nominative.split(' ');

      return this.search({
        limit: 9999999,
        where: {
          $and: nominativeSplit.map((n) => ({
            $or: [
              { firstName: { $regex: `${n}.*`, $options: 'i' } },
              { lastName: { $regex: `${n}.*`, $options: 'i' } },
              { businessName: { $regex: `${n}.*`, $options: 'i' } },
            ],
          })),
        },
        order: ['lastName'],
      });
    } else return of(null);
  }

  getContacts(): Observable<IContact[]> {
    // return of([sampleContact]);
    return this.http
      .get<IObjectList>(`${this.appConfigService.getApiGatewayURL()}/portale/contacts`)
      .pipe(map((res) => res.data));
  }

  getContact(id): Observable<IContact> {
    // return of(sampleContact);
    return this.http.get<any>(`${this.appConfigService.getApiGatewayURL()}/portale/contacts/${id}`);
  }

  saveContact(contact: IContact): Observable<IContact> {
    return this.http
      .post<IContact>(`${this.appConfigService.getApiGatewayURL()}/portale/contacts`, contact)
      .pipe(
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: 'Utenti',
            detail: 'Contato salvato correttamente',
          })
        )
      );
  }

  updateContact(contact: IContact, id: string): Observable<IContact> {
    return this.http
      .put<IContact>(`${this.appConfigService.getApiProxiedURL()}/contacts/updateContact/${id}`, contact)
      .pipe(
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: 'Utenti',
            detail: 'Contato salvato correttamente',
          })
        )
      );
  }

  deleteContact(id: string): Observable<void> {
    return this.http.delete<void>(`${this.appConfigService.getApiGatewayURL()}/portale/contacts/${id}`);
  }
}
