import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { merge, isEmpty } from 'lodash';

export const contactFormValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};
  if (isCompany(control)) {
    if (!control.value.businessName) {
      merge(errors, { businessName: { required: true, error: 'Il campo ragione sociale è obbilgatorio' } });
    }
  } else {
    if (control && control.value) {
      if (!control.value.firstName) {
        merge(errors, { firstName: { required: true, error: 'Il campo nome è obbilgatorio' } });
      }
      if (!control.value.lastName) {
        merge(errors, { lastName: { required: true, error: 'Il campo cognome è obbilgatorio' } });
      }
      if (!control.value.birthDate) {
        merge(errors, { birthDate: { required: true, error: 'Il campo data di nascita è obbilgatorio' } });
      }
    }
  }
  return isEmpty(errors) ? null : errors;
};
const isCompany = (control: UntypedFormGroup) => control && control.value && control.value.type === 'company';
