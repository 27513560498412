import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { InputBoxModule, UiModule } from '@renovars/ui-ng';
import { ApplicationsServices } from '../../services/applications.services';
import { FilesService } from '../../services/files.service';
import { SharedCoreModule } from '../../shared-core.module';
import { SiteModule } from '../site';
import { UtentiAdditionalDataFrom } from './components/forms/additional-data.form';
import { UtentiAdditionalInfo } from './components/pages/additional-info.component';
import { UtentiUpsertComponent } from './components/pages/upsert/utenti-upsert.component';
import { UtentiUpsertTabsComponent } from './components/pages/utenti-upsert-tabs.component';
import { UtentiComponent } from './components/utenti.component';
import { UtentiModuleOptions, UTENTI_CONFIG_PROVIDER } from './module.options';
import { UtentiRoutingModule } from './utenti-routing.module';
@NgModule({
  declarations: [
    UtentiComponent,
    UtentiUpsertComponent,
    UtentiUpsertTabsComponent,
    UtentiAdditionalInfo,
    UtentiAdditionalDataFrom,
  ],
  imports: [CommonModule,     FormsModule,
    UiModule, ReactiveFormsModule, UtentiRoutingModule, SharedCoreModule, SiteModule, InputBoxModule],
  providers: [UsersService, FilesService, ApplicationsServices],
  exports: [UtentiAdditionalDataFrom],
})
export class UtentiModule {
  static forRoot(config: UtentiModuleOptions): ModuleWithProviders<UtentiModule> {
    return {
      ngModule: UtentiModule,
      providers: [
        {
          provide: UTENTI_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
