<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Dati anagrafici</h2>
      </header>
    </div>
  </div>

  <div class="row mt-3 gy-2">
    <div class="col-sm-12">
      <fullstack-input-box
        [options]="{ label: 'Ragione Sociale *' }"
        formControlName="businessName"
        [error]="_formInfoState?.businessName?.error"
      >
      </fullstack-input-box>
    </div>

    <div class="col-sm-6">
      <fullstack-input-address-box
        [options]="{ label: 'Sede legale' }"
        formControlName="legalAddress"
        [error]="_formInfoState?.legalAddress?.error"
      >
      </fullstack-input-address-box>
    </div>

    <div class="col-sm-6">
      <fullstack-input-address-box
        [options]="{ label: 'Sede operativa' }"
        formControlName="workingAddresses"
        [error]="_formInfoState?.workingAddresses?.error"
      >
      </fullstack-input-address-box>
    </div>

    <div class="col-sm-4">
      <fullstack-input-box
        [options]="{ label: 'Numero REA' }"
        formControlName="reaNumber"
        [error]="_formInfoState?.reaNumber?.error"
      ></fullstack-input-box>
    </div>

    <div class="col-sm-4">
      <fullstack-input-box
        [options]="{ label: 'Provincia REA' }"
        formControlName="reaProvince"
        [error]="_formInfoState?.reaProvince?.error"
      ></fullstack-input-box>
    </div>

    <div class="col-sm-4">
      <fullstack-input-box
        [options]="{ label: 'Attiva dal' }"
        formControlName="since"
        type="number"
        [error]="_formInfoState?.since?.error"
      >
      </fullstack-input-box>
    </div>

    <div class="col-sm-4">
      <fullstack-input-box
        [options]="{ label: 'Codice ATECO' }"
        formControlName="atecoCode"
        [error]="_formInfoState?.atecoCode?.error"
      ></fullstack-input-box>
    </div>

    <div class="col-sm-4">
      <fullstack-input-box
        [options]="{ label: 'Numero di impiegati' }"
        formControlName="employeesNumber"
        type="number"
        [error]="_formInfoState?.employeesNumber?.error"
      >
      </fullstack-input-box>
    </div>

    <div class="col-sm-4">
      <fullstack-input-box
        [options]="{ label: 'Profitto annuo' }"
        formControlName="profitAmount"
        type="number"
        [error]="_formInfoState?.profitAmount?.error"
      >
      </fullstack-input-box>
    </div>

    <div class="col-sm-6">
      <fullstack-input-box
        [options]="{ label: 'Partita IVA *' }"
        formControlName="vatCode"
        [error]="_formInfoState?.vatCode?.error"
      ></fullstack-input-box>
    </div>

    <div class="col-sm-6">
      <fullstack-input-box
        [options]="{ label: 'Codice fiscale' }"
        formControlName="fiscalCode"
        [error]="_formInfoState?.fiscalCode?.error"
      ></fullstack-input-box>
    </div>

    <div class="col-sm-6">
      <fullstack-input-box
        [options]="{ label: 'IBAN' }"
        formControlName="iban"
        [error]="_formInfoState?.iban?.error"
      ></fullstack-input-box>
    </div>

    <div class="col-sm-6">
      <fullstack-input-box
        [options]="{ label: 'Codice destinatario' }"
        formControlName="sdiCode"
        [error]="_formInfoState?.sdiCode?.error"
      ></fullstack-input-box>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-12">
      <header class="page-header">
        <h2 class="tx-title-2">Contatti</h2>
      </header>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <fullstack-form-row-list [title]="'Email'" formControlName="emails" [controlsFn]="emailsGroupControl">
        <ng-template #rowTemplate let-control>
          <div class="row" [formGroup]="control">
            <div class="col-md-3" *ngIf="showMailType">
              <fullstack-input-select-box [options]="{ label: 'Tipo' }" formControlName="type">
                <sb-option
                  [value]="i"
                  *ngFor="
                    let i of [
                      'Info',
                      'Vendite',
                      'Marketing',
                      'Acquisti',
                      'Direzione',
                      'Assistenza',
                      'Tecnico',
                      'Amministrazione',
                      'Altre',
                      'Pec'
                    ]
                  "
                >
                  {{ i }}
                </sb-option>
              </fullstack-input-select-box>
            </div>

            <div class="col">
              <fullstack-input-box
                [options]="{ label: 'Email' }"
                formControlName="email"
              ></fullstack-input-box>
            </div>

            <div class="col-md-4" *ngIf="showMailType">
              <fullstack-input-box [options]="{ label: 'Note' }" formControlName="note"></fullstack-input-box>
            </div>
          </div>
        </ng-template>
      </fullstack-form-row-list>
    </div>

    <div class="col-12">
      <fullstack-form-row-list
        [title]="'Cellulare'"
        formControlName="phones"
        [controlsFn]="phonesGroupControl"
      >
        <ng-template #rowTemplate let-control>
          <div class="row" [formGroup]="control">
            <div class="col-md-3" *ngIf="showPhoneType">
              <fullstack-input-select-box [options]="{ label: 'Tipo' }" formControlName="type">
                <sb-option
                  [value]="i"
                  *ngFor="
                    let i of [
                      'Info',
                      'Vendite',
                      'Marketing',
                      'Acquisti',
                      'Direzione',
                      'Assistenza',
                      'Tecnico',
                      'Amministrazione',
                      'Altre',
                      'Pec'
                    ]
                  "
                >
                  {{ i }}
                </sb-option>
              </fullstack-input-select-box>
            </div>

            <div class="col-md-5">
              <fullstack-input-box
                [options]="{ label: 'Telefono', trim: true }"
                formControlName="phoneNumber"
              >
              </fullstack-input-box>
            </div>
          </div>
        </ng-template>
      </fullstack-form-row-list>
    </div>
  </div>

  <div *ngIf="showSources">
    <div class="row">
      <div class="col-12">
        <header class="page-header">
          <h2 class="tx-title-2">Dettagli</h2>
        </header>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <fullstack-input-select-box
          [options]="{ label: 'Stato' }"
          formControlName="state"
          [error]="_formInfoState?.state?.error"
        >
          <sb-option [value]="i" *ngFor="let i of ['Da verificare', 'Verificato']"> {{ i }} </sb-option>
        </fullstack-input-select-box>
      </div>

      <div class="col-sm-12 col-md-6">
        <fullstack-input-select-box
          [options]="{ label: 'Fonte Contatto *' }"
          formControlName="source"
          [error]="_formInfoState?.source?.error"
        >
          <sb-option [value]="i" *ngFor="let i of sources"> {{ i }} </sb-option>
        </fullstack-input-select-box>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Persona di riferimento</h2>
      </header>
    </div>
  </div>

  <div formGroupName="referencePerson">
    <div class="row mt-3">
      <div class="col-sm-12 col-md-6">
        <fullstack-input-box
          [options]="{ label: 'Nome *' }"
          formControlName="firstName"
        ></fullstack-input-box>
      </div>

      <div class="col-sm-12 col-md-6">
        <fullstack-input-box
          [options]="{ label: 'Cognome *' }"
          formControlName="lastName"
        ></fullstack-input-box>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 col-md-4">
        <fullstack-input-box [options]="{ label: 'Telefono' }" formControlName="phone"></fullstack-input-box>
      </div>

      <div class="col-sm-12 col-md-4">
        <fullstack-input-box [options]="{ label: 'Email' }" formControlName="email"></fullstack-input-box>
      </div>

      <div class="col-sm-12 col-md-4">
        <fullstack-input-box [options]="{ label: 'Ruolo' }" formControlName="role"></fullstack-input-box>
      </div>
    </div>
  </div>
</form>
