import { NgModule } from '@angular/core';
import { AccordionTableModule } from './accordion-table/accordion-table.module';

const TablesModules = [AccordionTableModule];

@NgModule({
  imports: TablesModules,
  exports: TablesModules,
})
export class TablesModule {}
