import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '../datepicker';
import { InputAddressBoxModule } from '../input-address-box';
import { InputBoxModule } from '../input-box';
import { InputMultipleValueModule } from '../input-multiple-value';
import { InputRegistrazioniModule } from '../input-registrazioni';
import { InputSelectBoxModule } from '../input-select-box';
import { InputTextareaModule } from '../input-textarea/input-textarea.module';
import { InputUserComponent } from './input-user.component';

const COMPONENTS = [InputUserComponent];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    InputBoxModule,
    DatePickerModule,
    InputAddressBoxModule,
    InputSelectBoxModule,
    InputMultipleValueModule,
    InputRegistrazioniModule,
    InputTextareaModule,
  ],
  providers: [],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputUserModule {}
