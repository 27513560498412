import { NgModule } from '@angular/core';

const DECLARATIONS = [];

@NgModule({
  declarations: DECLARATIONS,
  imports: [],
  providers: [],
  exports: [...DECLARATIONS],
})
export class SharedComponentsModule {}
