import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetRolesPipe } from './utils.pipe';
import { NameByIdPipe } from './name-by-id.pipe';

const DECLARATIONS = [GetRolesPipe, NameByIdPipe];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class PipesModule {}
