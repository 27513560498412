import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxMultipleButtonDirective, InputCheckBoxComponent } from './input-check-box.component';

const COMPONENTS = [InputCheckBoxComponent, CheckboxMultipleButtonDirective];

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputCheckBoxModule {}
