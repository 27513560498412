import { Component, ContentChildren, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TabDirective } from './tabs.directive';

@Component({
  selector: 'fullstack-tabs',
  templateUrl: './tabs.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent implements OnInit {
  @ContentChildren(TabDirective) tabs: Array<TabDirective>;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  remit($event: string) {
    this.valueChange.emit($event);
  }
}
