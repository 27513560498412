import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackingUrlDetailsComponent } from './tracking-url-details.component';

@NgModule({
  declarations: [TrackingUrlDetailsComponent],
  imports: [CommonModule],
  exports: [TrackingUrlDetailsComponent],
})
export class TrackingUrlModule {}
