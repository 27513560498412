import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexOf',
  pure: false
})
export class CheckboxMultipleIndexOfPipe implements PipeTransform {
  transform(items: string[], value: string): any {
    if (!items || !value) {
      return -1;
    }
    return items.indexOf(value);
  }
}
