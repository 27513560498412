import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KanbanComponent } from './kanban.component';
import { BoardDirective, CardBodyDirective, CardHeaderDirective } from './kanban.directives';
import { BoardFilterPipe } from './kanban.pipes';

const COMPONENTS = [KanbanComponent, CardHeaderDirective, CardBodyDirective, BoardDirective, BoardFilterPipe];

@NgModule({
  imports: [FormsModule, CommonModule, DragDropModule, ScrollingModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class KanbanModule {}
