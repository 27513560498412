import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GMapModule } from 'primeng/gmap';
import { AddressMapFormComponent } from './address-map-form.component';
import { CommonModule } from '@angular/common';

const COMPONENTS = [AddressMapFormComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [FormsModule, CommonModule, GMapModule, FormsModule, ReactiveFormsModule],
  exports: COMPONENTS,
})
export class AddressMapModule {}
