import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonRoles, IFileElement, IJwtPayload } from '@renovars/common';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, take } from 'rxjs';
import { AuthServices, FilesService } from '../../services';
import { NewFileDialogComponent } from './new-file-dialog.component';
import { NewFolderDialogComponent } from './new-folder-dialog.component';
import { SetVisibilityDialogComponent } from './set-visibility-dialog.component';

@Component({
  selector: 'renovars-file-manager',
  templateUrl: './file-manager.component.html',
  styles: [],
  providers: [DialogService],
})
export class FileManagerComponent implements OnInit {
  items: MenuItem[];
  @Input() fileElements: IFileElement[];
  @Input() canNavigateUp: string;
  @Input() path: string;

  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() fileAdded = new EventEmitter<{ name: string; file_id: string }>();
  @Output() elementRemoved = new EventEmitter<IFileElement>();
  @Output() elementRenamed = new EventEmitter<IFileElement>();
  @Output() navigatedDown = new EventEmitter<IFileElement>();
  @Output() elementMoved = new EventEmitter<{ element: IFileElement; moveTo: IFileElement }>();
  @Output() navigatedUp = new EventEmitter();
  @Output() viewRoleAdded = new EventEmitter<IFileElement>();

  @ViewChild('cm') cm: ContextMenu;
  @ViewChildren('item') item: QueryList<ElementRef>;

  private selected: BehaviorSubject<IFileElement>;
  canAddFileOrFolder = false;
  activeUser: IJwtPayload;
  constructor(
    public dialog: DialogService,
    private filesService: FilesService,
    private cdr: ChangeDetectorRef,
    private authService: AuthServices
  ) {
    if (!this.selected) {
      this.selected = new BehaviorSubject<IFileElement>(null);
    }
  }

  ngOnInit(): void {
    this.authService.user().subscribe((u) => {
      this.activeUser = u;
      if (u.roles.includes(CommonRoles.IS_ADMIN)) {
        this.adminMenu();
      } else {
        this.basicMenu();
      }
      this.cdr.detectChanges();
    });
  }

  private adminMenu() {
    this.canAddFileOrFolder = true;
    this.items = [
      {
        label: 'scarica',
        icon: 'pi pi-fw pi-download',
        command: (e) => {
          e.originalEvent.preventDefault();
          this.selected$.pipe(take(1)).subscribe((fe) => {
            if (!fe.isFolder) this.filesService.download(fe.file_id);
          });
        },
      },
      {
        label: 'rinomina',
        icon: 'pi pi-fw pi-pencil',
        command: (e) => {
          e.originalEvent.preventDefault();
          this.selected$.pipe(take(1)).subscribe((v) => this.renameElement(v));
        },
      },
      {
        label: 'imposta visibilità',
        icon: 'pi pi-fw pi-pencil',
        command: (e) => {
          e.originalEvent.preventDefault();
          this.selected$.pipe(take(1)).subscribe((v) => this.openSetVisibilityDialog(v));
        },
      },
      {
        label: 'elimina',
        icon: 'pi pi-fw pi-times',
        command: (e) => {
          e.originalEvent.preventDefault();
          this.selected$.pipe(take(1)).subscribe((fe) => {
            this.deleteElement(fe);
          });
        },
      },
    ];
  }

  private basicMenu() {
    this.canAddFileOrFolder = false;
    this.selected$.subscribe((fe) => {
      if (!fe.isFolder) {
        this.items = [
          {
            label: 'scarica',
            icon: 'pi pi-fw pi-download',
            command: (e) => {
              e.originalEvent.preventDefault();
              if (!fe.isFolder) this.filesService.download(fe.file_id);
            },
          },
        ];
      } else {
        this.items = [
          {
            label: 'naviga',
            icon: 'pi pi-link',
            command: (e) => {
              e.originalEvent.preventDefault();
              this.navigate(fe);
            },
          },
        ];
      }
    });
  }

  get selected$() {
    return this.selected.asObservable();
  }

  deleteElement(element: IFileElement) {
    this.elementRemoved.emit(element);
  }

  navigate(element: IFileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  moveElement(element: IFileElement, moveTo: IFileElement) {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  }

  openNewFolderDialog() {
    const dialogRef = this.dialog.open(NewFolderDialogComponent, {
      header: 'Crea una nuova cartella',
      width: '30%',
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    });
  }
  openNewFileDialog() {
    const dialogRef = this.dialog.open(NewFileDialogComponent, {
      header: 'Carica un nuovo File',
      width: '30%',
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.fileAdded.emit({ name: res.name, file_id: res.file_id });
      }
    });
  }
  openSetVisibilityDialog(element: IFileElement) {
    const dialogRef = this.dialog.open(SetVisibilityDialogComponent, {
      header: 'Seleziona il ruolo di chi può vedere il file',
      width: '30%',
      data: element,
    });
    dialogRef.onClose.subscribe((roles) => {
      if (roles) {
        element.canBeViewedBy = roles;
        this.viewRoleAdded.emit(element);
      }
    });
  }

  renameElement(element: IFileElement) {
    const dialogRef = this.dialog.open(NewFolderDialogComponent, {
      data: {
        element: element,
      },
      header: 'Rinomina',
      width: '30%',
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        element.name = res;
        this.elementRenamed.emit(element);
      }
    });
  }

  handleMenu($event) {
    $event.preventDefault();
    this.cm.toggle($event);
  }
  setSelected(element: IFileElement) {
    this.selected.next(element);
  }
}
