import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-day-time-slot',
  templateUrl: './day-time-slot.component.html',
  styleUrls: ['./day-time-slot.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => DayTimeSlotComponent),
      multi: true,
    },
  ],
})
export class DayTimeSlotComponent extends FormElement implements OnInit {
  MIN_HOUR = 7;
  MAX_HOUR = 22;

  @Input() slots = [];
  @Input() options: { maxHour: number; minHour: number } = { maxHour: this.MAX_HOUR, minHour: this.MIN_HOUR };

  startHours = [];
  endHours = [];
  weekDays = [
    {
      value: 1,
      name: 'Lunedì',
    },
    {
      value: 2,
      name: 'Martedì',
    },
    {
      value: 3,
      name: 'Mercoledì',
    },
    {
      value: 4,
      name: 'Giovedì',
    },
    {
      value: 5,
      name: 'Venerdì',
    },
    {
      value: 6,
      name: 'Sabato',
    },
    {
      value: 0,
      name: 'Domenica',
    },
  ];

  ngOnInit(): void {
    this.startHours = Array(this.options.maxHour - this.options.minHour)
      .fill(0)
      .map((_, i) => i + this.options.minHour);
    this.endHours = Array(this.options.maxHour - this.options.minHour + 1)
      .fill(0)
      .map((_, i) => i + this.options.minHour);
    this.slots = this.slots.map((slot) => {
      return {
        startHours: this.filterStartHours(slot.end),
        endHours: this.filterEndHours(slot.start),
        start: slot.start,
        end: slot.end,
        day: slot.day,
      };
    });
  }

  addSlot() {
    this.slots.push({
      day: 1,
      start: this.options.minHour,
      end: this.options.maxHour,
      endHours: this.filterEndHours(this.options.minHour),
      startHours: this.filterStartHours(this.options.maxHour),
    });
    this.onSlotChange(this.slots);
  }

  removeSlot(index) {
    this.slots.splice(index, 1);
    this.onSlotChange(this.slots);
  }

  checkStartRange(slotIndex) {
    this.slots[slotIndex].endHours = this.filterEndHours(this.slots[slotIndex].start);
    this.onSlotChange(this.slots);
  }

  checkEndRange(slotIndex) {
    this.slots[slotIndex].startHours = this.filterStartHours(this.slots[slotIndex].end);
    this.onSlotChange(this.slots);
  }

  filterStartHours(endHour): number[] {
    return this.startHours.filter((hour) => hour < endHour);
  }

  filterEndHours(startHour): number[] {
    return this.endHours.filter((hour) => hour > startHour);
  }

  writeValue(slots) {
    super.writeValue(slots);
    if (!slots) {
      this.slots = [];
    } else {
      slots = slots.map((slot) => {
        return {
          start: Number(slot.start),
          end: Number(slot.end),
          day: Number(slot.day),
          startHours: this.filterStartHours(slot.end),
          endHours: this.filterEndHours(slot.start),
        };
      });
      this.slots = [...slots];
    }
  }

  onSlotChange(slots) {
    this.value = slots.map((slot) => {
      return {
        start: Number(slot.start),
        end: Number(slot.end),
        day: Number(slot.day),
      };
    });
    this._onChange(this.value);
  }
}
