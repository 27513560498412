import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { emailCheck, fiscalCodeCheck, IContactIndividual, phoneCheck } from '@renovars/common';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let email = control.value;
    return !emailCheck(email) ? { emailCheck: { value: control.value } } : null;
  };
}

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let phone = control.value;
    return !phoneCheck(phone) ? { phoneCheck: { value: control.value } } : null;
  };
}
