<p-galleria
  [(value)]="primeGalleryData"
  [(visible)]="displayBasic"
  [containerStyle]="{ 'max-width': '50%' }"
  [numVisible]="9"
  [circular]="true"
  [fullScreen]="true"
  [showItemNavigators]="true"
  [showThumbnailNavigators]="false"
  [baseZIndex]="100000"
>
  <ng-template pTemplate="item" let-item>
    <img [src]="item.previewImageSrc" style="width: 100%; display: block" />
  </ng-template>
  <!-- <ng-template pTemplate="thumbnail" let-item>
    <div class="p-grid p-nogutter p-justify-center">
      <img [src]="item.thumbnailImageSrc" style="display: block" />
    </div>
  </ng-template> -->
</p-galleria>

<div class="gallery">
  <figure class="gallery__figure">
    <!-- <img
      *ngIf="imagesQueryList.toArray()[selectedIndex]"
      class="gallery__img"
      [src]="imagesQueryList.toArray()[selectedIndex].src"
      alt="images"
    /> -->
    <div *ngIf="itemsArray[selectedIndex] as item">
      <img class="gallery__img" [src]="storageUrl + '/' + item.file.id" alt="images" />

      <div *ngIf="item.tag" class="tags">
        <span class="badge badge-warning">{{ item.tag }}</span>
      </div>
    </div>

    <img *ngIf="!itemsArray[0]" class="gallery__img" [src]="'/assets/img/no-image.jpg'" alt="images" />
  </figure>
  <div class="actions btn-group">
    <button type="button" class="btn btn-secondary" (click)="download(selectedIndex)">
      <i class="fas fa-download"></i>
    </button>
    <button type="button" class="btn btn-secondary" (click)="_delete(selectedIndex)">
      <i class="far fa-trash-alt"></i>
    </button>
    <button type="button" class="btn btn-secondary" (click)="_info(selectedIndex)">
      <i class="fa fa-info"></i>
    </button>
    <button type="button" class="btn btn-secondary" (click)="displayBasic = true">
      <i class="fas fa-expand-arrows-alt"></i>
    </button>
  </div>

  <div class="gallery__slide-main">
    <div class="gallery__arrow" (click)="scrollLeft(gallerySlideElem)">
      <i class="fas fa-angle-left"></i>
    </div>
    <div #gallerySlideElem class="gallery__slide-thumb">
      <!-- <ng-container *ngFor="let img of imagesQueryList; let i = index">
        <figure class="gallery__fig-thumb">
          <img class="gallery__thumb" [src]="img.src" alt="images" (click)="selectedIndex = i" />
        </figure>
      </ng-container> -->
      <ng-container *ngFor="let item of itemsArray; let i = index">
        <figure class="gallery__fig-thumb">
          <img
            class="gallery__thumb"
            [src]="storageUrl + '/' + item.file.id"
            alt="images"
            (click)="selectedIndex = i"
          />
        </figure>
      </ng-container>
    </div>
    <div class="gallery__arrow" (click)="scrollRight(gallerySlideElem)">
      <i class="fas fa-angle-right"></i>
    </div>
    <label class="gallery__file"
      ><i class="fas fa-camera"></i>
      <input type="file" (change)="_add($event)" [accept]="accept" [size]="maxFileSize" multiple />
    </label>
    <label class="gallery__file" title="Cambia ordine delle foto" (click)="openSortModal()">
      <i class="fas fa-sort-amount-down-alt"></i>
    </label>
  </div>
</div>
