import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FormElement } from '../core/utils/form-element';
import { LocalDateAdapter } from './daterangepicker.component';

dayjs.extend(customParseFormat);
@Component({
  selector: 'fullstack-datepicker',
  templateUrl: './datepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    { provide: DateAdapter, useClass: LocalDateAdapter },
  ],
})
export class DatepickerComponent extends FormElement implements OnInit {
  @Input() options: { label: string; placeholder: string };
  @Input() error: string = null;
  ngOnInit(): void {}

  dateChange(date) {
    const inputDate = dayjs(date.target.value, 'DD/MM/YYYY');
    this._onChange(inputDate);
  }
}
