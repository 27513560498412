import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GmapsService } from '../core/services/gmaps.service';
import { InputAddressBoxComponent } from './input-address-box.component';

const COMPONENTS = [InputAddressBoxComponent];

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [GmapsService],
})
export class InputAddressBoxModule {}
