import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-modal',
  template: `<h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content class="mat-typography">
      <div class="d-flex"><i class="fas fa-exclamation-triangle fa-2x me-3"></i>{{ data.text }}</div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        mat-button
        class="btn btn-light ml-auto"
        type="button"
        data-dismiss="modal"
        (click)="dialogRef.close()"
      >
        <i class="icon-close me-2"></i>Annulla
      </button>
      <button
        mat-button
        class="btn btn-secondary ms-3"
        type="button"
        data-dismiss="modal"
        [mat-dialog-close]="{ confirmed: true }"
      >
        <i class="icon-check me-2"></i>Conferma
      </button>
    </mat-dialog-actions>`,
  styleUrls: ['./modals.component.scss'],
})
export class ConfirmModalComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}
}
