import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxMultipleButtonComponent, CheckboxMultipleButtonDirective } from './checkbox-multiple-button.component';
import {CheckboxMultipleIndexOfPipe} from "./checkbox-multiple-indexOf.pipe";

const COMPONENTS = [CheckboxMultipleButtonComponent, CheckboxMultipleButtonDirective, CheckboxMultipleIndexOfPipe];

@NgModule({
  declarations: COMPONENTS,
  imports: [FormsModule, CommonModule],
  exports: COMPONENTS,
})
export class CheckboxMultipleButtonModule {}
