import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { IRegistration } from '@renovars/common';

@Component({
  selector: 'fullstack-applications-menu',
  template: `
    <div
      #sideApplicationsMenu
      class="sideoffset d-none animated fadeInRightBig"
      style="height: 200px; width: 15rem;"
    >
      <div class="sideoffset__container--fluid">
        <div class="sideoffset__header">
          <button class="sideoffset__close" (click)="applicationsMenulineToggle()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="row">
          <div class="col-12 text-center" *ngFor="let app of registration">
            <div *ngIf="app?.name">
              <a
                href="{{ 'https://' + app?.url }}"
                class="btn btn-secondary btn-block rounded mb-4 mt-1"
                style="background-color: {{ app?.color }};"
              >
                {{ app?.name }}
              </a>
              <span *ngIf="!registration || registration.length <= 1">
                Non hai accesso a nessuna applicazione
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-secondary">
      <div class="row justify-content-end">
        <div class="col-1 text-end">
          <button class="btn btn-timeline" (click)="applicationsMenulineToggle()">
            <i class="fab fa-buromobelexperte text-white" style="font-size: 25px;"></i>
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      fullstack-applications-menu {
        position: fixed;
        z-index: 1000;
        width: 100%;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ApplicationsMenuComponent implements OnInit {
  @ViewChild('sideApplicationsMenu') sideApplicationsMenu: ElementRef;

  @Input() registration: IRegistration[] = [];
  constructor() {}

  ngOnInit(): void {}

  public applicationsMenulineToggle() {
    this.sideApplicationsMenu.nativeElement.classList.toggle('d-none');
  }
}
