import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import 'popper.js';
import { GMapModule } from 'primeng/gmap';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AddressMapModule } from './address-map-form/address-map.module';
import { CarouselModule } from './carousel/carousel.module';
import { CheckboxButtonModule } from './checkbox-button/checkbox-button.module';
import { CheckboxMultipleButtonModule } from './checkbox-multiple-button/checkbox-multiple-button.module';
import { CoreModule } from './core/core.module';
import { DatatableModule } from './datatable/datatable.module';
import { DatePickerModule } from './datepicker/datepicker.module';
import { DayTimeSlotModule } from './day-time-slot';
import { EntitiesMapModule } from './entities-map';
import { FormRowListModule } from './form-row-list/form-row-list.module';
import { FullcalendarModule } from './fullcalendar';
import { ImageGalleryModule } from './image-gallery/image-gallery.module';
import { InputAddressBoxModule } from './input-address-box/input-address-box.module';
import { InputBoxModule } from './input-box/input-box.module';
import { InputCheckBoxModule } from './input-check-box/input-check-box.module';
import { InputFileUploadModule } from './input-file-upload';
import { InputFileUploadModuleNew } from './input-file-upload-new/input-file-upload.module';
import { InputMultipleValueModule } from './input-multiple-value/input-multiple-value.module';
import { InputNumberModule } from './input-number/input-number.module';
import { InputRegistrazioniModule } from './input-registrazioni';
import { InputSelectBoxModule } from './input-select-box/input-select-box.module';
import { InputSelectButtonModule } from './input-select-button/input-select-button.module';
import { InputTextareaModule } from './input-textarea/input-textarea.module';
import { InputTitledBoxModule } from './input-titled-box/input-titled-box.module';
import { InputToggleModule } from './input-toggle/input-toggle.module';
import { InputUserModule } from './input-user/input-user.module';
import { KanbanModule } from './kanban/kanban.module';
import { LayoutModule } from './layout/layout.module';
import { ModalsModule } from './modals/modals.module';
import { RadioButtonModule } from './radio-button/radio-button.module';
import { FullstackSliderModule } from './slider';
import { SpinnerModule } from './spinner/spinner.module';
import { StepTimelineModule } from './step-timeline';
import { TablesModule } from './tables/tables.module';
import { TimelineModule } from './timeline/timeline.module';
import { TimePickerModule } from './timepicker/timepicker.module';
import { ChatboxModule } from './chatbox/chatbox.module';

const MODULES = [
  CoreModule,
  DatatableModule,
  ToastModule,
  DragDropModule,
  GMapModule,
  AddressMapModule,
  CarouselModule,
  CheckboxButtonModule,
  CheckboxMultipleButtonModule,
  DatatableModule,
  FormRowListModule,
  ImageGalleryModule,
  InputAddressBoxModule,
  InputBoxModule,
  InputMultipleValueModule,
  InputSelectBoxModule,
  KanbanModule,
  LayoutModule,
  ModalsModule,
  DatePickerModule,
  InputTitledBoxModule,
  InputCheckBoxModule,
  RadioButtonModule,
  SpinnerModule,
  InputUserModule,
  InputRegistrazioniModule,
  InputFileUploadModule,
  InputFileUploadModuleNew,
  InputTextareaModule,
  TimePickerModule,
  TimelineModule,
  DayTimeSlotModule,
  FullcalendarModule,
  InputToggleModule,
  InputNumberModule,
  TablesModule,
  EntitiesMapModule,
  FullstackSliderModule,
  OverlayPanelModule,
  MatExpansionModule,
  MatButtonModule,
  MatListModule,
  MatStepperModule,
  MatIconModule,
  InputSelectButtonModule,
  TooltipModule,
  StepTimelineModule,
  ChatboxModule,
  MatTabsModule,
];
@NgModule({
  imports: MODULES,
  exports: MODULES,
})
export class UiModule {}
