import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-input-multiple-select-box',
  templateUrl: './input-multiple-select-box.component.html',
  styleUrls: ['./input-multiple-select-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMutipleSelectBoxComponent),
      multi: true,
    },
  ],
})
export class InputMutipleSelectBoxComponent extends FormElement {
  @Input() options: {
    label: string;
    placeholder: string;
    disabled: boolean;
    filter: boolean;
    showToggleAll: boolean;
    showHeader: boolean;
  } = {
    label: '',
    placeholder: 'Seleziona uno o più valori',
    disabled: false,
    filter: false,
    showToggleAll: false,
    showHeader: false,
  };

  @Input() values: { name: string; id: string }[];
  @Input() key = 'code';
  @Input() name = 'label';

  modelChange(event) {
    if (this._onChange) {
      this._onChange(event);
    }
  }
}
