import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmModalComponent } from './confirm-modal.component';
import { ModalsService } from './modals.service';

const COMPONENTS = [ConfirmModalComponent,];

@NgModule({
  imports: [FormsModule, CommonModule, MatDialogModule],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, MatDialogModule],
  providers: [ModalsService],
})
export class ModalsModule {}
