<h5 *ngIf="options?.label">{{ options?.label }}:</h5>
<div class="d-flex flex-wrap">
  <div *ngFor="let c of checkboxButtons; let i = index" class="m-1 no-gutter">
    <button
      type="button"
      class="btn btn-xs rounded"
      [ngClass]="((value | indexOf : c.value) !== -1) ? 'btn-secondary' : 'btn-outline-primary'"
      (click)="onSelect(c, i)"
    >
      {{ c.label }}
    </button>
  </div>
</div>
