import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../pipes/pipes.module';
import { CofEventModal } from './cof-event-modal';

const DECLARATIONS = [CofEventModal];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule, PipesModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class ModalsModule {}
