import { Directive, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { HeaderService } from './header.service';
@Directive({
  selector: '[header-body]',
})
export class HeaderBodyDirective implements OnInit, OnDestroy {
  constructor(public templateRef: TemplateRef<any>, private headerService: HeaderService) {}
  ngOnDestroy(): void {
    this.headerService.headerBodyDirective.pop();
  }
  ngOnInit(): void {
    this.headerService.headerBodyDirective.push(this);
  }
}

@Directive({
  selector: '[header-menu]',
})
export class HeaderMenuDirective implements OnInit, OnDestroy {
  constructor(public templateRef: TemplateRef<any>, private headerService: HeaderService) {}
  ngOnDestroy(): void {
    this.headerService.headerMenuDirective.pop();
  }
  ngOnInit(): void {
    this.headerService.headerMenuDirective.push(this);
  }
}
