import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSelectButtonComponent } from './input-select-button.component';

const COMPONENTS = [InputSelectButtonComponent];

@NgModule({
  imports: [FormsModule, CommonModule, SelectButtonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class InputSelectButtonModule {}
