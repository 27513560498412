<ng-template header-body>
  <div class="text-end align-items-center">
    <button class="btn btn-header" [routerLink]="['./detail']">
      <i class="fas fa-plus cl-medium fa-1x"></i>
    </button>
  </div>
</ng-template>

<fullstack-datatable [list]="sites$ | async" [cmItems]="cmItems" (selected)="selected = $event" [maxRows]="100">
  <div t-header>
    <div t-column>
      <ng-template t-col-title>Indirizzo</ng-template>
    </div>
    <div t-column field="name" clientFilter="name">
      <ng-template t-col-title>Nome</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Descrizione</ng-template>
    </div>
    <div t-column *ngIf="phoneVisibile">
      <ng-template t-col-title>Telefono</ng-template>
    </div>
    <div t-column *ngIf="bcCodeVisible" field="bcCode" clientFilter="bcCode">
      <ng-template t-col-title>Oggetto di costo BC</ng-template>
    </div>
    <div t-column *ngIf="areaVisibile" field="area" clientFilter="area">
      <ng-template t-col-title>Area</ng-template>
    </div>
  </div>

  <ng-template t-body let-row>
    <td>{{ row?.address?.formatted_address }}</td>
    <td>{{ row?.name }}</td>
    <td>{{ row?.description }}</td>
    <td *ngIf="phoneVisibile">{{ row?.phone }}</td>
    <td *ngIf="bcCodeVisible">{{ row?.bcCode }}</td>
    <td *ngIf="areaVisibile">{{ row?.area }}</td>
  </ng-template>
</fullstack-datatable>
