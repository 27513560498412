export enum RecruitRoles {
  ADMIN = 'is_admin',
  STORE_MANAGER = 'is_store_manager',
  HR_OFFICE = 'is_ufficio_hr',
  CONSUMER = 'is_consumer_api',
  DIRECTOR = 'is_director',
  AREA_MANAGER = 'is_area_manager',
  SHOW_ROOM_MANAGER = 'is_show_room_manager',
  CANDIDATE_VIEWER = 'candidate_viewer',
  CANDIDATE_MANAGER = 'candidate_manager',
  CANDIDATE_DELETER = 'candidate_deleter',
  CANDIDATE_CONSUMER_VIEWER = 'candidate_consumer_viewer',
  CANDIDATE_CONSUMER_MANAGER = 'candidate_consumer_manager',
  CANDIDATE_CONSUMER_DELETER = 'candidate_consumer_deleter',
  EXCLUDE_FROM_ASSIGNMENT = 'exclude_from_assignment',
  EXCLUDE_FROM_INTERVIEW_NOTIFICATION = 'exclude_from_interview_notification',
  INTERVIEW_RESULT_VIEWER = 'interview_result_viewer',
  ASSISTANCE_EMAIL = 'assistance_email',
  SUPERVISOR = 'supervisor',
  COORDINATOR = 'is_coordinator',
  SM_VIP = 'is_vip_sm',
  WORKER_MANAGER = 'is_worker_manager',
  HR_SPECIALIST = 'is_hr_specialist',
  APPOINTEMENT_NOTIFICATION = 'appointment_notification',
}
export enum RecruitRolesLabels {
  'is_admin' = 'Admin',
  'is_ufficio_hr' = 'Recruiter',
  'is_hr_specialist' = 'Hr Specialist',
  'is_director' = 'Direttore Commerciale',
  'is_area_manager' = 'Coordinatore SM',
  'is_show_room_manager' = 'Showroom Manager',
  'is_store_manager' = 'Store Manager',
  'is_coordinator' = 'Coordinatore WM',
  'is_vip_sm' = 'Store Manager VIP',
  'is_worker_manager' = 'Worker Manager',
}

export const RecruitRolesLevels = [
  RecruitRoles.WORKER_MANAGER,
  RecruitRoles.STORE_MANAGER,
  RecruitRoles.SM_VIP,
  RecruitRoles.SHOW_ROOM_MANAGER,
  RecruitRoles.COORDINATOR,
  RecruitRoles.AREA_MANAGER,
  RecruitRoles.DIRECTOR,
];

export function isStoreManager(roles: string[]) {
  return (
    roles.includes(RecruitRoles.STORE_MANAGER) ||
    roles.includes(RecruitRoles.SM_VIP) ||
    roles.includes(RecruitRoles.WORKER_MANAGER)
  );
}
