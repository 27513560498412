import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { appInitializer } from '@renovars/fe-core-libs/services/app-init.service';
import { AuthServices } from '../../services/auth.services';
import { AppConfigService } from './app-config.service';
import { IAppConfig } from './interfaces/app-config.interface';

@NgModule({
  providers: [{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthServices] }],
})
export class AppConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: AppConfigModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: IAppConfig): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [
        AppConfigService,
        {
          provide: 'APP_CONFIG',
          useValue: config,
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders<AppConfigModule> {
    return {
      ngModule: AppConfigModule,
      providers: [AppConfigService],
    };
  }
}
