import { Directive, Input } from '@angular/core';

@Directive({
  selector: 'tab',
})
export class TabDirective {
  @Input() label: string;
  @Input() url: string;
  @Input() disabled: boolean;
  @Input() value: string;
}
