export enum FiRoles {
  IS_ADMIN = 'is_admin',
  IS_DIRETTORE_COMMERCIALE = 'is_direttore_commerciale',
  IS_AREA_MANAGER = 'is_area_manager',
  IS_REGIONAL_MANAGER = 'is_regional_manager',
  IS_STORE_MANAGER = 'is_store_manager',
  IS_AGENTE_SENIOR = 'is_agente_senior',
  IS_AGENTE_JUNIOR = 'is_agente_junior',
  IS_AGENTE_NEW = 'is_agente_new',
  IS_HR_OFFICE = 'is_hr_office',
  IS_CALLCENTER = 'is_callcenter',
  IS_BACK_OFFICE = 'is_back_office',
  IS_PROPERTY_FINDER = 'is_property_finder',
  PURCHASE_MANAGER = 'purchase_manager',
  IS_AMMINISTRATIVO = 'is_amministrativo',
  IS_MAINTAINER = 'is_maintainer',
  IS_REASSIGNER = 'is_reassigner',
  IS_OVERRIDER_STATE = 'is_overrider_state',
}

export const FiRolesLevels = [
  FiRoles.IS_HR_OFFICE,
  FiRoles.IS_AGENTE_NEW,
  FiRoles.IS_AGENTE_JUNIOR,
  FiRoles.IS_AGENTE_SENIOR,
  FiRoles.IS_STORE_MANAGER,
  FiRoles.IS_REGIONAL_MANAGER,
  FiRoles.IS_AREA_MANAGER,
  FiRoles.IS_DIRETTORE_COMMERCIALE,
];

export function getUpline(roleStart: FiRoles) {
  let roleLevelStartIndex = FiRolesLevels.findIndex((r) => r == roleStart);
  return FiRolesLevels.slice(roleLevelStartIndex);
}
