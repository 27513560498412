<label class="card card--form"
  ><span class="card-body"
    ><span class="label-control">{{ options.label }}</span>
    <div *ngIf="!checkboxButtons.length">
      <div class="form-input">
        <label class="check-control">
          <input
            class="check-control__input"
            type="checkbox"
            [ngModel]="value"
            (ngModelChange)="_onChange($event); refreshUI($event)"
            [disabled]="isDisabled"
          /><span class="check-control__dot"></span>
        </label>
        <div *ngIf="!hideLabel">{{ currentLabelValue }}</div>
      </div>
    </div>
    <div *ngIf="checkboxButtons.length" style="display: flex" class="m-1">
      <span *ngFor="let c of checkboxButtons" style="display: flex; align-items: center">
        <span class="me-2">{{ c.label }}</span>
        <label class="check-control">
          <input
            class="check-control__input"
            type="checkbox"
            [ngModel]="c.value"
            (ngModelChange)="c._onChange($event)"
          />
          <span class="check-control__dot"></span>
        </label>
      </span></div></span
></label>
