<div class="row mt-4">
  <div class="col-12">
    <header class="page-header">
      <h2 class="tx-title-2">Fonte</h2>
    </header>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <h2 class="tx-subtitle">Campagna</h2>
      </div>
      <div class="col-6">
        <h2 class="tx-subtitle">Fonte</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h6 class="tx-sm text-center text-bold">{{ candidate && candidate.candidateCampaign }}</h6>
      </div>
      <div class="col-6">
        <h6 class="tx-sm text-center text-bold">{{ candidate && candidate.candidateSource }}</h6>
      </div>
    </div>
  </div>
</div>
