import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatepickerComponent } from './datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { DaterangepickerComponent } from './daterangepicker.component';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from './datepicker.adpter';
import { CustomRangePanelComponent } from './custom-range-panel.component';
import { DatepickerHeaderComponent } from './datepicker-header.component';
import { CardModule } from 'primeng/card';
import {ButtonModule} from 'primeng/button';

const COMPONENTS = [DatepickerComponent, DaterangepickerComponent, CustomRangePanelComponent, DatepickerHeaderComponent];


@NgModule({
  imports: [FormsModule, CommonModule, MatDatepickerModule, MatNativeDateModule, CardModule,ButtonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class DatePickerModule { }
