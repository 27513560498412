import { UntypedFormGroup } from '@angular/forms';
export const setFieldsProperties = (formInfoState, form) => {
  if (formInfoState) {
    Object.keys(formInfoState).map((field) => {
      const regola = formInfoState[field];
      const cc = field.split('.');
      let pp = '';
      let oo = '';
      for (let i = 0; i <= cc.length; i++) {
        pp = cc.slice(0, i).join('.');
        oo = cc.slice(i, cc.length).join('.');
        if (form.get(pp) && regola.error) {
          const errors = { ...form.get(pp).errors, [oo]: regola };
          form.get(pp).setErrors(errors, { emitEvent: false });
        }
      }
    });
  } else {
    resetFormError(form);
  }
};

export const resetFormError = (formControl) => {
  if (formControl.controls) {
    formControl.setErrors(null, { emitEvent: false });
    Object.keys(formControl.controls).map((cmp) => resetFormError(formControl.get(cmp)));
  } else {
    formControl.setErrors(null, { emitEvent: false });
    return false;
  }

  return false;
};

export function setParentForm(parentForm: UntypedFormGroup, form: UntypedFormGroup) {
  if (parentForm) {
    Object.keys(form.controls).forEach((k) => {
      parentForm.addControl(k, form.controls[k]);
    });
  }
}
