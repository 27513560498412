import { Pipe, PipeTransform } from '@angular/core';
import { IContactCompany, IContactIndividual } from '@renovars/common';

@Pipe({ name: 'contactName' })
export class ContactNamePipe implements PipeTransform {
  constructor() {}

  transform(contact: IContactCompany | IContactIndividual) {
    let res;
    if (contact) {
      if ((<IContactCompany>contact).businessName) res = (<IContactCompany>contact).businessName;
      else if ((<IContactIndividual>contact).lastName)
        res = (<IContactIndividual>contact).firstName + ' ' + (<IContactIndividual>contact).lastName;
    }
    return res;
  }
}
