export const CofSites = [
  {
    street_number: '54',
    route: 'Viale John Fitzgerald Kennedy',
    locality: 'Aversa',
    country: 'Italia',
    short_country: 'IT',
    province: 'CE',
    postalCode: '81031',
    region: 'Campania',
    formatted_address: 'Viale John Fitzgerald Kennedy, 54, 81031 Aversa CE, Italia',
    coords: [40.9700879, 14.2129579],
    radius: '0',
  },
  {
    street_number: '8',
    route: 'Via Tivoli',
    locality: 'Roma',
    country: 'Italia',
    short_country: 'IT',
    province: 'RM',
    postalCode: '00156',
    region: 'Lazio',
    formatted_address: 'Via Tivoli, 8, 00156 Roma RM, Italia',
    coords: [41.9324238, 12.5900727],
    radius: '0',
  },
];
