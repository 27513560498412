import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { IApplication, IUser } from '@renovars/common';
import { AppConfigService } from '@renovars/fe-core-libs/features/app-config';
import { ApplicationsServices, AuthServices } from '@renovars/fe-core-libs/services';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { Observable } from 'rxjs';
import { SchedulingServices } from '../../../../services/scheduling.services';

@Component({
  selector: 'facile-profile',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="content" *permissions="['is_admin']">
        <fullstack-input-user formControlName="user"></fullstack-input-user>

        <fullstack-input-registrazioni
          [registrazioni]="user?.registrations"
          [applications]="applications$ | async"
          [applicationsCanAdd]="applications$ | async"
        >
        </fullstack-input-registrazioni>

        <div class="row mt-5">
          <div class="col-12">
            <header class="page-header border-bottom-0">
              <h2 class="tx-title-3">Stato registrazione calendario</h2>
            </header>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <button
              *ngIf="!user?.data?.avanoaId"
              type="button"
              class="btn btn-secondary rounded"
              (click)="signUpScheduling()"
            >
              Calendar scollegato clicca qui per collegare
            </button>
            <div *ngIf="user?.data?.avanoaId" class="alert alert-primary" role="alert">
              Calendar collegato correttamente: {{ user?.data?.avanoaId }}
              <button type="button" class="btn btn-secondary rounded m-2" (click)="signOutScheduling()">
                Scollega calendario
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class ProfileComponent implements OnInit {
  form = this.fb.group({
    user: new UntypedFormControl(null, []),
  });

  applications$: Observable<IApplication[]>;
  user: IUser;

  constructor(
    private fb: UntypedFormBuilder,
    private applicationService: ApplicationsServices,
    private schedulingServices: SchedulingServices,
    private authService: AuthServices,
    private usersService: UsersService,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.applicationService.applications().subscribe((apps) => {
      apps ? apps.filter((app) => app.id === this.appConfig.getApplicationId()) : [];
    });

    this.getUser();
  }

  getUser() {
    this.authService.user().subscribe((user) => {
      this.form.patchValue({ user: user });
      this.user = user;
    });
  }

  signUpScheduling() {
    this.schedulingServices
      .signUp(
        this.user.id,
        `${this.appConfig.getApiGatewayURL()}/scheduling/success?accountId={accountId}`,
        `${this.appConfig.getApiGatewayURL()}/scheduling/error?error=error`,
        `${document.location.href}`
      )
      .subscribe((res) => {
        window.location.href = res.url;
      });
  }

  signOutScheduling() {
    this.schedulingServices.signOut(this.user.id).subscribe((res) => this.getUser());
  }
}
