<aside class="sidebar" id="sidebar">
  <a class="sidebar__logo sidebar__logo--main" (click)="SidebarControl()"
    ><img src="{{ options.logoUrl }}" alt="logo" /></a
  ><a class="sidebar__logo sidebar__logo--min" (click)="SidebarControl()"
    ><img src="{{ options.logoMinUrl }}" alt="logo"
  /></a>
  <!-- navbar.pug srt-->
  <nav class="nav navbar flex-column" data-simplebar>
    <ul *ngIf="sidebarItems" class="navbar-nav">
      <ng-container *ngFor="let t of sidebarItems">
        <li #item class="nav-item">
          <a *ngIf="t.externalLink" class="nav-link" title="{{ t.label }}" [href]="t.url"
            ><i class="fas {{ t.icon || 'fa-user' }} nav-icon"></i
            ><span class="nav-label">{{ t.label }}</span></a
          >
          <a
            *ngIf="!t.externalLink && t.subItems[0]"
            #parentElem
            class="nav-link"
            style="cursor: pointer;"
            [ngClass]="{ active: checkActive(item) }"
            title="{{ t.label }}"
            (click)="toggleSubitem(parentElem)"
            ><i class="fas {{ t.icon || 'fa-user' }} nav-icon"></i><span class="nav-label">{{ t.label }}</span
            ><i class="icon-arrow-right nav-arrow"></i
          ></a>
          <a
            *ngIf="!t.externalLink && !t.subItems[0]"
            #parentElem
            class="nav-link"
            title="{{ t.label }}"
            href="#"
            [routerLinkActive]="['active']"
            [routerLink]="t.url && !t.subItems[0] ? t.url : null"
            (click)="toggleSubitem(parentElem)"
            ><i class="fas {{ t.icon || 'fa-user' }} nav-icon"></i
            ><span class="nav-label">{{ t.label }}</span></a
          >
          <div *ngIf="t.subItems[0]" class="nav-collapse collapse" [ngClass]="{ show: checkActive(item) }">
            <ul class="subnav">
              <ng-container *ngFor="let c of t.subItems; let i = index">
                <li class="nav-item">
                  <a
                    *ngIf="!c.externalLink"
                    href="#"
                    class="subItem nav-link"
                    [routerLinkActive]="['active']"
                    [routerLink]="c.url ? t.url + '/' + c.url : ''"
                    >{{ c.label }}</a
                  >
                  <a *ngIf="c.externalLink" class="nav-link" title="{{ c.label }}" [href]="c.url"
                    ><i class="fas {{ t.icon || 'fa-user' }} nav-icon"></i
                    ><span class="nav-label">{{ c.label }}</span></a
                  >
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
  </nav>
  <!-- navbar.pug end-->
  <div class="sidebar__footer">
    <div class="sidebar-user">
      <div class="sidebar-user__initials">{{ initials }}</div>
      <div class="sidebar-user__content">
        <div class="sidebar-user__name" (click)="goToProfile()">{{ user }}</div>
        <div class="sidebar-user__arrow" (click)="logout.emit()">
          <i class="fas fa-sign-out-alt"></i>
        </div>
      </div>
    </div>
  </div>
</aside>
