import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepTimelineComponent } from './step-timeline.component';
import { TimelineModule as TimelinePrimeNgModule } from 'primeng/timeline';

@NgModule({
  declarations: [StepTimelineComponent],
  imports: [CommonModule, TimelinePrimeNgModule],
  exports: [StepTimelineComponent],
})
export class StepTimelineModule {}
