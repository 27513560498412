import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IBaseCandidate } from '@renovars/common/recruit';
import ICofEvents from '../../../../../libs/shared/recruit/src/lib/interfaces/events/cof-events.interface';

@Component({
  selector: 'cof-event-modal',
  templateUrl: `cof-event-modal.html`,
  styles: [``],
})
export class CofEventModal implements OnInit {
  event: ICofEvents;

  constructor(
    public dialogRef: MatDialogRef<CofEventModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.event = data.event;
  }

  ngOnInit(): void {}

  goToDetail(event: ICofEvents) {
    const candidate = event.cache.candidate as IBaseCandidate;
    this.router.navigateByUrl(`/candidati/details/detail-info?id=${candidate._id}`);
  }
  gotoList(event: ICofEvents) {
    const candidate = event.cache.candidate as IBaseCandidate;
    this.router.navigateByUrl(`/candidati?id=${candidate?._id}`);
  }

  close() {
    this.dialogRef.close();
  }
  // move() {
  //   this.dialogRef.close({ move: true, event: this.event });
  // }
}
