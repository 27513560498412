<label class="card card--form">
  <span class="card-body">
    <div>
      <span class="label-control">{{ options?.label }}</span>
    </div>
    <div>
      <p-multiSelect
        [options]="values"
        [ngModel]="value"
        (ngModelChange)="modelChange($event)"
        [defaultLabel]="options.placeholder"
        [disabled]="options.disabled"
        [optionLabel]="name"
        [optionValue]="key"
        [filter]="options.filter"
        [showToggleAll]="options.showToggleAll"
        [showHeader]="options.showHeader"
      ></p-multiSelect>
    </div>
  </span>
</label>
