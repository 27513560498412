import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InterviewComponent } from './interview.component';
const appointmentRoutes: Routes = [
  {
    path: '',
    component: InterviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(appointmentRoutes)],
  exports: [RouterModule],
})
export class InterviewRoutingModule {}
