import { NgModule } from '@angular/core';
import {
  CalendarioModule,
  DomainValuesModule,
  GuardsModule,
  NetworkModule,
  ProfileModule,
  SiteModule,
  UtentiModule,
} from '@renovars/core-ng';
import { environment } from '../../environments/environment';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { UtentiService } from '../services/utenti.service';
import { InterviewModule } from './interview/interview.module';
import { CofEventModal } from '../modals/cof-event-modal';
import { PipesModule } from '../pipes/pipes.module';
import { CofRolesLabels } from '@renovars/common/recruit';

const DECLARATIONS = [];

@NgModule({
  declarations: DECLARATIONS,
  imports: [
    HomeModule,
    LoginModule,
    GuardsModule,
    UtentiModule.forRoot({
      userMetaEndpoint: `${environment.apiGatewayURL}/cof/user-meta`,
      service: new UtentiService(),
      excludeField: {
        site: true,
        bc: true,
        acceptStar: true,
      },
    }),
    NetworkModule.forRoot({ networkURL: `${environment.apiGatewayURL}/cof/network`, labels: CofRolesLabels }),
    InterviewModule,
    DomainValuesModule.forRoot({
      apiApplicationURL: `${environment.apiGatewayURL}/cof`,
    }),
    ProfileModule,
    CalendarioModule.forRoot({
      eventModalClass: CofEventModal,
      eventsEndpoint: `${environment.apiGatewayURL}/cof/events`,
    }),
    PipesModule,
    SiteModule.forRoot({
      url: `${environment.apiGatewayURL}/cof`,
      exclude: { bcCode: false, phone: false },
    }),
  ],
  providers: [],
  exports: [...DECLARATIONS],
})
export class FeauturesModule {}
