import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class UtilsService {
  setRegexCriteria(criteriaObj, field, value) {
    if (value) {
      criteriaObj[field] = { $regex: '.*' + value + '.*', $options: 'i' };
    } else {
      criteriaObj[field] = undefined;
    }
  }

  setDateCriteria(criteriaObj, field, value) {
    const date = moment(value);
    if (value) {
      criteriaObj[field] = { $gte: date.startOf('day').toDate(), $lte: date.endOf('day').toDate() };
    } else {
      criteriaObj[field] = undefined;
    }
  }

  setCriteria(criteriaObj, field, value) {
    if (value) {
      criteriaObj[field] = value;
    } else {
      criteriaObj[field] = undefined;
    }
  }
}
