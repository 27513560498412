import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplication, IObjectList } from '@renovars/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../features';

@Injectable()
export class ApplicationsServices {
  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  applications(): Observable<IApplication[]> {
    return this.http
      .get<IObjectList>(`${this.appConfigService.getApiGatewayURL()}/portale/applications`)
      .pipe(map((res) => res.data));
  }

  application(applicationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.appConfigService.getApiGatewayURL()}/portale/applications/${applicationId}`
    );
  }
}
