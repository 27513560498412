import { AuthServices } from './auth.services';

export function appInitializer(authServices: AuthServices) {
  return () =>
    new Promise((resolve) => {
      authServices.refreshToken().subscribe();
      setInterval(() => authServices.refreshToken().subscribe(), 300000);
      resolve(null);
    });
}
