<div [formGroup]="form">
  <div>
    <div class="row">
      <div class="col-12">
        <header class="page-header">
          <h2 class="tx-title-2">Dati fonte</h2>
        </header>
      </div>
    </div>

    <div class="mt-2 row gy-2">
      <div class="col-sm-6">
        <fullstack-input-box
          [options]="{ label: 'Nome fonte' }"
          formControlName="label"
        ></fullstack-input-box>
      </div>

      <div class="col-sm-6">
        <fullstack-input-select-box [options]="{ label: 'Tipo' }" formControlName="type">
          <sb-option
            [key]="'code'"
            [value]="i"
            *ngFor="let i of domainValuesList$ | async | domainValuesFilter: 'source_lead_type'"
          >
            {{ i.label }}
          </sb-option>
        </fullstack-input-select-box>
      </div>

      <div class="col-sm-6">
        <fullstack-input-box
          [options]="{ label: 'Nome campagna' }"
          formControlName="campaign"
        ></fullstack-input-box>
      </div>

      <div class="col-sm-6">
        <fullstack-input-select-box [options]="{ label: 'Gruppo' }" formControlName="group">
          <sb-option
            [key]="'code'"
            [value]="i"
            *ngFor="let i of domainValuesList$ | async | domainValuesFilter: 'source_group_type'"
          >
            {{ i.label }}
          </sb-option>
        </fullstack-input-select-box>
      </div>

      <!-- <div class="col-sm-6">
        <fullstack-input-check-box
          [options]="{ label: 'Campagna attiva' }"
          formControlName="isCampaingActive"
        ></fullstack-input-check-box>
      </div> -->
    </div>
  </div>
</div>
