import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-input-box',
  templateUrl: './input-box.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputBoxComponent),
      multi: true,
    },
  ],
  styles: [
    `
      .p-inputnumber-input {
        border: 0px;
      }
    `,
  ],
})
export class InputBoxComponent extends FormElement implements OnInit {
  @Input() options: { label: string; placeholder: string; disabled: boolean; trim: boolean };
  @Input() type = 'text';
  @Input() error: string = null;

  ngOnInit(): void {}

  modelChange(event) {
    this.value = event;
    if (this._onChange) {
      if (this.options && this.options.trim) {
        this._onChange(this.type === 'text' ? this.value.trim().replace(' ', '') : +this.value);
      } else {
        this._onChange(this.type === 'text' ? this.value : +this.value);
      }
    }
  }
}
