import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEvent, IPaginateRequest, IPaginateResponse } from '@renovars/common';
import { IFiAppointmentSearchDTO } from '@renovars/common/facileimmobiliare';
import { Observable } from 'rxjs';
import { CalendarioModuleConfig, CALENDARIO_CONFIG_PROVIDER } from '../features/calendario/module.options';

@Injectable()
export class CalendarioService {
  constructor(
    private http: HttpClient,
    @Inject(CALENDARIO_CONFIG_PROVIDER) private config: CalendarioModuleConfig
  ) {}

  search(paginateRequest: IPaginateRequest<IFiAppointmentSearchDTO>): Observable<IPaginateResponse<IEvent>> {
    return this.http.post<any>(`${this.config.eventsEndpoint}/search`, paginateRequest);
  }
}
