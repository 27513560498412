import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ChatboxComponent } from './chatbox.component';

const COMPONENTS = [ChatboxComponent];

@NgModule({
  imports: [FormsModule, CommonModule, MatListModule, MatIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [],
})
export class ChatboxModule {}
