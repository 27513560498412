import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import { isNil } from 'lodash';
import { IContact, IContactCompany, IContactIndividual } from '../interfaces/contacts';
import { ContactTypeEnum } from '../interfaces/contacts/contact.interface';

export function getContactFullname(contact: IContact): string {
  let fullname;
  if (contact.type == ContactTypeEnum.INDIVIDUAL) {
    const individual = <IContactIndividual>contact;
    fullname = `${individual.firstName} ${individual.lastName}`;
  } else if (contact.type == ContactTypeEnum.COMPANY) {
    const company = <IContactCompany>contact;
    fullname = company.businessName;
  }

  return fullname;
}

export function emailCheck(email: string) {
  return /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi.test(
    email
  );
}

export function phoneCheck(phone: string) {
  return /^((\+|00)?[0-9]{2})?(\d{9,10})$/.test(phone);
}

export function phoneCheckThreeDigit(phone: string) {
  return /^((\+|00)?[0-9]{2})?(\d{3,10})$/.test(phone);
}

export function fiscalCodeCheck(anagraphic: IContactIndividual) {
  if (isNil(anagraphic.gender)) {
    throw new Error('Sesso non specificato');
  }
  if (isNil(anagraphic.firstName)) {
    throw new Error('Nome non specificato');
  }
  if (isNil(anagraphic.lastName)) {
    throw new Error('Cognome non specificato');
  }
  if (isNil(anagraphic.birthDate)) {
    throw new Error('Data di nascita non specificata');
  }

  const cfValidator = codiceFiscaleUtils.Validator.codiceFiscale(anagraphic.fiscalCode);

  if (cfValidator.invalid) {
    throw new Error('Formato Codice fiscale non valido');
  }

  const firstNameFromCF = anagraphic.fiscalCode.substring(3, 6);

  if (codiceFiscaleUtils.Pattern.cfFirstName(anagraphic.firstName).test(firstNameFromCF) == false) {
    throw new Error('Codice fiscale non valido: Nome');
  }
  const lastNameFromCF = anagraphic.fiscalCode.substring(0, 3);

  if (codiceFiscaleUtils.Pattern.cfLastName(anagraphic.lastName).test(lastNameFromCF) == false) {
    throw new Error('Codice fiscale non valido: Cognome');
  }

  const gender: codiceFiscaleUtils.Genders = anagraphic.gender.toLowerCase() == 'uomo' ? 'M' : 'F';
  if (cfValidator.mismatchGender(gender)) {
    throw new Error('Codice fiscale non valido: Sesso');
  }

  if (cfValidator.mismatchBirthDate(anagraphic.birthDate)) {
    throw new Error('Codice fiscale non valido: Data di nascita');
  }

  return true;
}
