import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageGalleryComponent } from './image-gallery.component';
import { FormsModule } from '@angular/forms';

const COMPONENTS = [ImageGalleryComponent];

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class ImageGalleryModule {}
