import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeLibsModule, SharedCoreModule } from '@renovars/core-ng';
import { UiModule } from '@renovars/ui-ng';
import { ChartModule } from 'primeng/chart';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipeLibsModule,
    UiModule,
    SharedCoreModule,
    ChartModule,
  ],
  providers: [],
})
export class SharedModule {}
