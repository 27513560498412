import {
  Component,
  ContentChildren,
  Directive,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Directive({
  selector: 'checkbox',
})
export class CheckboxMultipleButtonDirective {
  @Input() label;
  @Input() value;
}

@Component({
  selector: 'fullstack-checkbox-multiple-button',
  templateUrl: './checkbox-multiple-button.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultipleButtonComponent),
      multi: true,
    },
  ],
})
export class CheckboxMultipleButtonComponent extends FormElement implements OnInit {
  @ContentChildren(CheckboxMultipleButtonDirective) checkboxButtons: QueryList<any>;
  @Input() options: { label: String; placeholder: String };

  ngOnInit(): void {}

  writeValue(val: string[]): void {
    this.value = val ? [...val] : [];
  }

  onSelect(val: CheckboxMultipleButtonDirective, index) {
    let foundIndex = this.value.indexOf(val.value);
    if (foundIndex !== -1) {
      this.value.splice(foundIndex, 1);
    } else {
      this.value.push(val.value)
    }
    this._onChange(this.value);
  }
}

