import { IUtentiModuleService } from '../../../../../libs/fe/core-ng/src';
import { IUser } from '../../../../../libs/shared/common/src';
import { CofRoles, CofRolesLevels } from '../../../../../libs/shared/recruit/src';

export class UtentiService implements IUtentiModuleService {
  calculateNetworkPriority(user: IUser, applicationId: string) {
    const reg = user.registrations?.find((r) => r.applicationId == applicationId);

    const roles = reg?.roles;

    let maxLevel = 0;
    roles?.forEach((r) => {
      const level = CofRolesLevels.indexOf(<CofRoles>r);
      if (maxLevel < level) {
        maxLevel = level;
      }
    });

    return CofRolesLevels.slice(maxLevel + 1);
  }
}
